<template>
  <v-menu
    v-model="filterMenu" 
    offset-y 
    :nudge-bottom="5" 
    :close-on-content-click="false">
    <template #activator="{ on }">
      <v-btn
        rounded 
        v-on="on" 
        text
        color="grey darken-2"
        class="ml-auto text-capitalize">
        <v-icon left>mdi-filter-outline</v-icon>
        Status
        <v-badge
          class="ml-1 mb-2"
          color="accent"
          :content="filterCount"
          :value="filterCount > 0">
        </v-badge>
      </v-btn>
    </template>
    <v-card width="350">
      <v-card-text>
        <div class="mt-2">
          <v-row dense justify="center">
            <v-col class="ma-0 pa-0">
              <v-select
                :value="value"
                label="Promo Status Filter"
                :items="promo_statuses"
                item-text="name"
                item-value="id"
                :menu-props="{ offsetY: true }"
                multiple
                chips
                clearable
                :disabled="loading"
                background-color="#fff"
                item-color="action"
                dense
                hide-details
                outlined
                @change="$emit('update:value', $event)">
                <template v-slot:selection="{ item, index }">
                  <v-chip small v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1"
                    class="grey--text text-caption">
                    (+{{ value.length - 1}})
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  data() {
    return {
      filterMenu: false
    }
  },
  name: 'PromoStatusFilter',
  props: {
    value: Array,
    loading: Boolean
  },
  computed: {
    filterCount() {
      return this.value?.length || 0
    },
    promo_statuses () {
      return this.$store.getters.promo_statuses.filter(status => status.constant !== 'CANCELLED')
    }
  }
}
</script>