<template>
  <v-select
    v-model="selectedFilters"
    label="Search Filters"
    :items="availableFilters"
    item-text="name"
    item-value="key"
    background-color="input"
    :menu-props="{
      value: selectMenu, offsetY: true, maxHeight: '800', maxWidth: '900', 
      closeOnContentClick: false, closeOnClick: true, openOnClick: true, allowOverflow: true
    }"
    :disabled="disabled"
    style="border-top-right-radius: 0px; border-bottom-right-radius: 0px; border-right: 0px;"
    multiple
    outlined
    hide-details
    small-chips
    dense>
    <template v-slot:selection="{ attrs, item }">
      <template v-if="item.key === 'store_party_ids' && (params.store_party_ids.length > 0)">
        <v-chip
          :color="item.color"
          dark
          :style="item.length > 1 ? 'max-width: 70%' : 'max-width: 90%'"
          small>
          <span class="text-truncate">
            {{ `${item.name}` }}
          </span>
          <span 
            v-for="(group, index) in selectedAdGroups"
            :key="group.id">
            <template v-if="index === 1">
              ({{ selectedAdGroups.length }})
            </template>
          </span>
          <v-icon
            small
            right
            @click="removeFilter(item.key, true)">
            $delete
          </v-icon>
        </v-chip>
      </template>
      <template v-else-if="item.key === 'promo_category_ids' && (params.promo_category_ids.length > 0)">
        <v-chip
          :color="item.color"
          dark
          :style="item.length > 1 ? 'max-width: 70%' : 'max-width: 90%'"
          small>
          <span class="text-truncate">
            {{ `${item.name}` }}
          </span>
          <span 
            v-for="(group, index) in selectedCategories"
            :key="group.id">
            <template v-if="index === 1">
              ({{ selectedCategories.length }})
            </template>
          </span>
          <v-icon
            small
            right
            @click="removeFilter(item.key, true)">
            $delete
          </v-icon>
        </v-chip>
      </template>
      <template v-else-if="item.key === 'vendor_party_ids' && (params.vendor_party_ids.length > 0)">
        <v-chip
          :color="item.color"
          dark
          :style="item.length > 1 ? 'max-width: 70%' : 'max-width: 90%'"
          small>
          <span class="text-truncate">
            {{ `${item.name}` }}
          </span>
          <span 
            v-for="(group, index) in selectedVendors"
            :key="group.id">
            <template v-if="index === 1">
              ({{ selectedVendors.length }})
            </template>
          </span>
          <v-icon
            small
            right
            @click="removeFilter(item.key, true)">
            $delete
          </v-icon>
        </v-chip>
      </template>
      <template v-else-if="item.key === 'subvendor_party_ids' && (params.subvendor_party_ids.length > 0)">
        <v-chip
          :color="item.color"
          dark
          :style="item.length > 1 ? 'max-width: 70%' : 'max-width: 90%'"
          small>
          <span class="text-truncate">
            {{ `${item.name}` }}
          </span>
          <span 
            v-for="(group, index) in selectedSubvendors"
            :key="group.id">
            <template v-if="index === 1">
              ({{ selectedSubvendors.length }})
            </template>
          </span>
          <v-icon
            small
            right
            @click="removeFilter(item.key, true)">
            $delete
          </v-icon>
        </v-chip>
      </template>
      <template v-else-if="item.key === 'category_manager_id' && (params.category_manager_id.length > 0)">
        <v-chip
          :color="item.color"
          dark
          :style="item.length > 1 ? 'max-width: 70%' : 'max-width: 90%'"
          small>
          <span class="text-truncate">
            {{ `${item.name}` }}
          </span>
          <span 
            v-for="(group, index) in selectedCategoryManager"
            :key="group.id">
            <template v-if="index === 1">
              ({{ selectedCategoryManager.length }})
            </template>
          </span>
          <v-icon
            small
            right
            @click="removeFilter(item.key, true)">
            $delete
          </v-icon>
        </v-chip>
      </template>
      <template v-else-if="item.key === 'dc_party_names' && (params.dc_party_names.length > 0)">
        <v-chip
          :color="item.color"
          dark
          :style="item.length > 1 ? 'max-width: 70%' : 'max-width: 90%'"
          small>
          <span class="text-truncate">
            {{ `${item.name}` }}
          </span>
          <span 
            v-for="(group, index) in selectedDistributionCenters"
            :key="group.id">
            <template v-if="index === 1">
              ({{ selectedDistributionCenters.length }})
            </template>
          </span>
          <v-icon
            small
            right
            @click="removeFilter(item.key, true)">
            $delete
          </v-icon>
        </v-chip>
      </template>
      <v-chip
        v-else
        v-bind="attrs"
        :color="item.color || null"
        :dark="Boolean(item.color)"
        small>
        {{ getFilterText(item) }}
        <v-icon
          small
          right
          @click="removeFilter(item.key, item.type === 'boolean' ? false : true)">
          $delete
        </v-icon>
      </v-chip> 
    </template>
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item>
        <v-list-item-action>
          <v-simple-checkbox
            v-on="isCheckboxDisabled(item) ? null : on"
            v-model="attrs.inputValue"
            color="primary"
            :disabled="isCheckboxDisabled(item)"
            :ripple="false">
          </v-simple-checkbox>
        </v-list-item-action>
        <v-list-item-content style="overflow: visible">
          <v-autocomplete
            v-if="item.key === 'promo_category_ids'"
            v-model="params.promo_category_ids"
            :label="item.name"
            :items="sortedCategories"
            item-text="name"
            item-value="id"
            item-color="action"
            hide-details
            multiple
            small-chips
            deletable-chips
            @change="handleChangedArray($event, item.key)"
            @keyup.enter="search"
            clearable
            outlined
            dense>
            <template v-slot:item="{ item, attrs: { inputValue, disabled, ripple } }">
              <div style="width: 100%">
                <template>
                  <v-list-item class="ma-0 pa-0" :disabled="disabled">
                    <v-list-item-action >
                      <v-checkbox color="action" :input-value="inputValue" :disabled="disabled" :ripple="ripple"/>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{item.name}}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="(item.id === 'exclude-amap' && !includeExcludeEba) || item.id === 'exclude-eba'"/>
                </template> 
              </div>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-else-if="item.key === 'category_manager_id'"
            v-model="params.category_manager_id"
            :label="item.name"
            :items="categoryManagers"
            item-text="name"
            item-value="id"
            item-color="action"
            hide-details
            multiple
            small-chips
            deletable-chips
            @change="handleChangedArray($event, item.key)"
            @keyup.enter="search"
            clearable
            outlined
            dense>
          </v-autocomplete>
          <v-autocomplete
            v-else-if="item.key === 'store_party_ids'"
            v-model="params.store_party_ids"
            :label="item.name"
            :items="userAdGroups"
            item-text="name"
            item-value="id"
            item-color="action"
            hide-details
            multiple
            small-chips
            deletable-chips
            @change="handleChangedArray($event, item.key)"
            @keyup.enter="search"
            clearable
            outlined
            dense>
          </v-autocomplete>
          <v-autocomplete
            v-else-if="item.key === 'dc_party_names'"
            v-model="params.dc_party_names"
            :label="item.name"
            :items="distributionCenters"
            item-text="name"
            item-value="name"
            item-color="action"
            hide-details
            multiple
            small-chips
            deletable-chips
            @change="handleChangedArray($event, item.key)"
            @keyup.enter="search"
            clearable
            outlined
            dense>
          </v-autocomplete>
          <div id="created-on-date-menu" v-else-if="item.key === 'created_on'" style="position: relative">
            <v-text-field
              background-color="white"
              item-color="action"
              clearable
              dense
              hide-details
              label="Created On"
              outlined
              readonly
              prepend-inner-icon="mdi-calendar"
              @click:clear="removeFilter(item.key, true)"
              @click="showCreatedOnMenu = !showCreatedOnMenu"
              @keyup.enter="search"
              :value="dateFormatted(params.created_on)">
            </v-text-field>
            <v-card
              v-if="showCreatedOnMenu"
              style="position: absolute; top: 40px; z-index: 11;">
              <v-date-picker
                :show-current="false"
                v-model="params.created_on"
                color="action"
                no-title
                @change="handleChangedDate(item)">
              </v-date-picker>
            </v-card>
          </div>
          <div id="ad-date-menu" v-else-if="item.key === 'ad_date'" style="position: relative">
            <v-text-field
              background-color="white"
              clearable
              dense
              hide-details
              label="Ad Date"
              outlined
              readonly
              prepend-inner-icon="mdi-calendar"
              @click:clear="removeFilter(item.key, true)"
              @click="showAdDateMenu = !showAdDateMenu"
              @keyup.enter="search"
              :value="dateFormatted(params.ad_date)">
            </v-text-field>
            <v-card
              v-if="showAdDateMenu"
              style="position: absolute; top: 40px; z-index: 11;">
              <v-date-picker
                :show-current="false"
                v-model="params.ad_date"
                color="action"
                no-title
                @change="handleChangedDate(item)">
              </v-date-picker>
            </v-card>
          </div>
          <VendorSelect
            v-else-if="item.key === 'vendor_party_ids'"
            :selectedVendors.sync="selectedVendors">
          </VendorSelect>
          <SubvendorSelect
            v-else-if="item.key === 'subvendor_party_ids'"
            :selectedSubvendors.sync="selectedSubvendors">
          </SubvendorSelect>
          <div
            v-else-if="item.type === 'boolean'"
            class="ma-0 pa-0">
            <v-switch
              v-model="params[`${item.key}`]"
              class="pa-0 ma-0"
              :label="item.name"
              dense
              hide-details
              color="primary"
              @change="handleInputValue(item)">
            </v-switch>
          </div>
          <v-text-field
            v-else
            v-model="params[`${item.key}`]"
            :label="item.name"
            hide-details
            background-color="input"
            :prefix="item.prefix || null"
            :type="item.type"
            :autofocus="(selectedFilters.length === 0) && item.key === 'promo_name'"
            dense
            :clearable="item.key === 'lump_sum_allowance' ? (params[item.key] !== true) : true"
            @input="attrs.inputValue ? null : handleInputValue(item)"
            @click:clear="removeFilter(item.key)"
            @keyup.enter="search"
            outlined>
          </v-text-field>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
//components
import VendorSelect from '@/components/VendorSelect'
import SubvendorSelect from '@/components/SubvendorSelect'
// mixins
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
// third-party
import { debounce, cloneDeep } from 'lodash'
export default {
  data () {
    return {
      selectMenu: false,
      selectedVendors: [],
      selectedSubvendors: [],
      loading: false,
      showCreatedOnMenu: false,
      showAdDateMenu: false,
      selectedFilters: [],
      filters: [
        { name: 'Promo Name', key: 'promo_name', type: 'text' },
        { name: 'Promo Number', key: 'promo_number', type: 'text' },
        { name: 'Promo Category', key: 'promo_category_ids', color: 'amber darken-2' },
        { name: 'Category Manager', key: 'category_manager_id', tenants: ['pricechopper', 'alliance-retail-group'] },
        { name: 'Created On', key: 'created_on', type: 'text' },
        { name: 'Ad Date', key: 'ad_date', type: 'text', tenants: ['alliance-retail-group'] },
        { name: 'Ad Group', key: 'store_party_ids', color: 'cyan darken-1' },
        { name: 'Lump Sum Allowance', text: 'Lump Sum', key: 'lump_sum_allowance', type: 'number', prefix: '$', tenants: ['awg'] },
        { name: 'Ad Fee', text: 'Ad Fee', key: 'lump_sum_allowance', type: 'number', prefix: '$', tenants: ['pricechopper', 'alliance-retail-group'] },
        { name: 'Item Id', key: 'item_id', type: 'text' },
        { name: 'UPC', key: 'upc', type: 'text' },
        { name: 'Department', text: 'Dept', key: 'department', type: 'text' },
        { name: 'Vendor', key: 'vendor_party_ids', color: 'red'},
        { name: 'Subvendor', key: 'subvendor_party_ids', color: 'red', tenants: ['alliance-retail-group', 'pricechopper']},
        { name: 'Distribution Center', key: 'dc_party_names', color: 'blue', tenants: ['jbg'] },
        { name: 'Perishable', key: 'perishable', type: 'boolean', color: 'primary', tenants: ['alliance-retail-group'] },
        { name: 'Digital Coupon', key: 'digital_coupon', type: 'boolean', color: 'primary', tenants: ['alliance-retail-group'] },
        { name: 'Private Label', key: 'private_label', type: 'boolean', color: 'primary', tenants: ['alliance-retail-group'] },
        { name: 'DSD', key: 'dsd', type: 'boolean', color: 'primary', tenants: ['alliance-retail-group'] },
      ],
      params: {
        promo_name: '',
        promo_number: '',
        item_id: '',
        upc: '',
        department: '',
        lump_sum_allowance: null,
        promo_category_ids: [],
        category_manager_id: [],
        store_party_ids: [],
        vendor_party_ids: [],
        subvendor_party_ids: [],
        created_on: '',
        dc_party_names: [],
        perishable: false,
        digital_coupon: false,
        private_label: false,
        dsd: false
      }
    }
  },
  name: 'SearchFilters',
  components: { VendorSelect, SubvendorSelect },
  mixins: [userAccess, utils],
  props: {
    value: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    selectedVendors: {
      handler (newValue) {
        if (newValue.length > 0) {
          sessionStorage.setItem('vendor_filters', JSON.stringify(newValue))
        } else {
          sessionStorage.removeItem('vendor_filters')
        }
        this.handleChangedVendors(newValue, 'vendor_party_ids')
      }
    },
    selectedSubvendors: {
      handler (newValue) {
        if (newValue.length > 0) {
          sessionStorage.setItem('subvendor_filters', JSON.stringify(newValue))
        } else {
          sessionStorage.removeItem('subvendor_filters')
        }
        this.handleChangedSubvendors(newValue, 'subvendor_party_ids')
      }
    },
    selectedCategories: {
      handler (newValue) {
        if (this.promoCategories?.length > 0 && this.includeExcludeAmap) {
          const amap = this.promoCategories.find(c => c.name === 'AMAP')
          const excludeAmap = this.promoCategories.find(c => c.name === 'Exclude AMAP')
          const amapSelected = newValue?.some(c => c.name === 'AMAP')
          const excludeAmapSelected = newValue?.some(c => c.name === 'Exclude AMAP')
          if (amapSelected && excludeAmap) {
            excludeAmap.disabled = true
          } else if (excludeAmapSelected && amap) {
            amap.disabled = true
          } else {
            if (amap) amap.disabled = false
            if (excludeAmap) excludeAmap.disabled = false
          }
        }
        if (this.promoCategories?.length > 0 && this.includeExcludeEba) {
          const eba = this.promoCategories.find(c => c.name === 'Early Buy Allowance')
          const excludeEba = this.promoCategories.find(c => c.name === 'Exclude EBA')
          const ebaSelected = newValue?.some(c => c.name === 'Early Buy Allowance')
          const excludeEbaSelected = newValue?.some(c => c.name === 'Exclude EBA')
          if (ebaSelected && excludeEba) {
            excludeEba.disabled = true
          } else if (excludeEbaSelected && eba) {
            eba.disabled = true
          } else {
            if (eba) eba.disabled = false
            if (excludeEba) excludeEba.disabled = false
          }
        }
      }
    },
    activeFilters: {
      handler (newValue) {
        if (newValue) {
          if (this.isSelected('lump_sum_allowance') && !this.params.lump_sum_allowance) {
            return this.params.lump_sum_allowance = true
          }
          this.updateSearchFilters()
        }
      },
      deep: true
    },
    selectedFilters: {
      handler (newValue) {
        if (newValue?.includes('lump_sum_allowance') && !this.params.lump_sum_allowance) {
          this.params.lump_sum_allowance = true
        }
      },
      deep: true
    }
  },
  created() {
    if (sessionStorage.vendor_filters) {
      this.selectedVendors = JSON.parse(sessionStorage.vendor_filters)
    }
    if (sessionStorage.subvendor_filters) {
      this.selectedSubvendors = JSON.parse(sessionStorage.subvendor_filters)
    }
    if (sessionStorage.search_filters) {
      const stored = JSON.parse(sessionStorage.search_filters)
      this.params = { ...this.params, ...stored }
      const activeKeys = Object.keys(stored).filter(key => typeof stored[key] === 'boolean' || Boolean(stored[key]))
      if (activeKeys.length > 0) this.selectedFilters.push(...activeKeys)
    }
    document.addEventListener('click', this.handleClick)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClick)
  },
  computed: {
    availableFilters () {
      return this.filters.filter(f => (!f.tenants || f.tenants.includes(this.$auth.tenant)))
    },
    activeFilters () {
      return Object.keys(this.params).filter(this.isActive)
    },
    sortedCategories () {
      let categories = [...this.promoCategories.map(pc => {
        pc.disabled = false 
        return pc
      })]
      categories = this.sortByKey(categories, 'name')
      if (categories.some(c => c.name === 'Exclude EBA')) {
        categories.unshift(categories.splice(categories.findIndex(c => c.name === 'Exclude EBA'), 1)[0])
      }
      if (categories.some(c => c.name === 'Exclude AMAP')) {
        categories.unshift(categories.splice(categories.findIndex(c => c.name === 'Exclude AMAP'), 1)[0])
      }
      return categories
    },
    selectedCategories () {
      return this.sortedCategories.filter(c => this.params.promo_category_ids?.includes(c?.id))
    },
    sortedCategoryManagers() {
      const category_managers = [...this.categoryManagers]
      return this.sortByKey(category_managers, 'name')
    },
    selectedCategoryManager() {
      return this.sortedCategoryManagers.filter(c => this.params.category_manager_id.includes(c?.id))
    },
    selectedAdGroups () {
      return this.userAdGroups.filter(p => this.params.store_party_ids.includes(p?.id))
    },
    selectedDistributionCenters() {
      return this.distributionCenters.filter(p => this.params.dc_party_names.includes(p?.name))
    },
    adGroups () {
      let groups = this.$store.getters.adGroups
      if (this.$auth.tenant == 'awg') {
        groups = groups.filter(group => 
          group.attributes?.AD_GROUP_IS_ACTIVE?.toLowerCase() == 'true'
        )
      }
      return groups
    },
    categoryManagers () {
      return this.$store.getters.categoryManagers
    },
    userAdGroups () {
      let groups = [...this.adGroups]
      if (this.limitAccessByRelatedStores) {
        groups = groups.filter(group => {
          return this.relatedAdGroupsPartyIds.includes(group?.id)
        })
      }
      return this.sortByKey(groups, 'name')
    },
    distributionCenters () {
      return this.$store.getters.distributionCenters
    },
    promoCategories () {
      const promo_cats = cloneDeep(this.promo_categories)
      if (this.includeExcludeAmap) {
        if (!promo_cats.some(pc => pc.id === 'exclude-amap')) {
          promo_cats.push({ name: 'Exclude AMAP', id: 'exclude-amap' })
        }
      }
      if (this.includeExcludeEba) {
        if (!promo_cats.some(pc => pc.id === 'exclude-eba')) {
          promo_cats.push({ name: 'Exclude EBA', id: 'exclude-eba' } )
        }
      }
      return promo_cats
    },
    includeExcludeAmap () {
      return ['pricechopper', 'alliance-retail-group', 'awg'].includes(this.$auth.tenant)
    },
    includeExcludeEba () {
      return ['pricechopper'].includes(this.$auth.tenant)
    }
  },
  methods: {
    handleClick (e) {
      e.stopPropagation()
      const adDateMenu = document.querySelector('#ad-date-menu')
      const createdOnDateMenu = document.querySelector('#created-on-date-menu')
      if (adDateMenu && !adDateMenu.contains(e.target) && this.showAdDateMenu) {
          this.showAdDateMenu = false
      }
      if (createdOnDateMenu && !createdOnDateMenu.contains(e.target) && this.showCreatedOnMenu) {
        this.showCreatedOnMenu = false
      }
    },
    handleChangedDate (item) {
      this.showCreatedOnMenu = false
      this.showAdDateMenu = false
      this.handleInputValue(item)
    },
    dateFormatted (dateVal) {
      if (dateVal) {
        return this.$config.formatDate(dateVal)
      }
      return dateVal
    },
    isCheckboxDisabled (item) {
      if (item.type === 'boolean') {
        return false
      }
      if (Array.isArray(this.params[item.key])) {
        return (this.params[item.key].length === 0)
      }
      return !this.params[item.key] && (item.key !== 'lump_sum_allowance')
    },
    isActive (key) {
      const filter = this.filters.find(f => f.key === key)
      const isActiveBool = filter.type === 'boolean' && typeof this.params[key] !== 'undefined'
      if (this.isSelected(key) && (Boolean(this.params[key]) || isActiveBool)) {
        const value = this.params[key]
        return Array.isArray(value) ? (value.length > 0) : true
      }
      return false
    },
    getFilterText (item) {
      if (item.key === 'lump_sum_allowance') {
        const value = (this.params.lump_sum_allowance === true)
          ? 'Any'
          : `$${this.params[item.key]}`
        return `${item.text}: ${value}`
      } else if (item.key === 'created_on' || item.key === 'ad_date') {
        return `${item.name}: ${this.$config.formatDate(this.params[item.key])}`
      } else if (item.key === 'category_manager_id') {
        return this.selectedCategoryManager?.name
      }
      return `${item.text || item.name}: "${this.params[item.key]}"`
    },
    removeFilter (key, clearValue = false) {
      if (key === 'lump_sum_allowance' && !clearValue) return
      const index = this.selectedFilters.indexOf(key)
      if (index > -1) {
        this.selectedFilters.splice(index, 1)
        if (clearValue) this.params[key] = null
      }
    },
    handleInputValue: debounce(function (item) {
      const isActiveBool = item.key.type === 'boolean' && typeof this.params[item.key] !== 'undefined'
      if ((isActiveBool || Boolean(this.params[item.key])) && !this.isSelected(item.key)) {
        this.selectedFilters.push(item.key)
      }
    }, 200),
    handleChangedArray (newValue, key) {
      if ((newValue.length > 0) && !this.isSelected(key)) {
        this.selectedFilters.push(key)
      } else if ((newValue.length === 0) && this.isSelected(key)) {
        this.removeFilter(key)
      }
    },
    handleChangedVendors (newValue, key) {
      this.params.vendor_party_ids = newValue.flatMap(v => v ? v.id : [])
      this.handleChangedArray(newValue, key)
    },
    handleChangedSubvendors (newValue, key) {
      this.params.subvendor_party_ids = newValue.flatMap(sv => sv ? sv.id : [])
      this.handleChangedArray(newValue, key)
    },
    isSelected (key) {
      return this.selectedFilters.includes(key)
    },
    search () {
      this.$emit('search')
    },
    updateSearchFilters () {
      const filtersObj = this.activeFilters.reduce((obj, key) => {
        obj[key] = this.params[key]
        return obj
      }, {})
      this.$emit('input', filtersObj)
      if (this.activeFilters.length > 0) {
        sessionStorage.setItem('search_filters', JSON.stringify(filtersObj))
      } else {
        sessionStorage.removeItem('search_filters')
      }
    }
  }
}
</script>