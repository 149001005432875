<template>
  <v-container class="ma-0 pa-0" fluid>
    <template>
      <v-tabs
        grow 
        dark
        background-color="primary"
        hide-slider
        :active-class="'active'"
        v-model="tab">
        <v-tab>Promotion Details</v-tab>
        <v-tab :disabled="!existingPromo">Group / Store</v-tab>
        <v-tab :disabled="!existingPromo || itemsDisabled">Items</v-tab>
      </v-tabs>
      <keep-alive>
        <span v-if="tab === 0">
          <div class="mt-4 ml-4">
            <router-link class="primary--text" :to="{ path: '/promos'}" style="text-decoration:none;">
              <v-icon color="primary">mdi-chevron-left</v-icon>Return to Promotions
            </router-link>
          </div>
          <div class="mx-8 mt-4">
            <span class="text-body-2">
              <b>Promotion Category:</b> {{ promoTypeDisplayName }}
            </span>
            <span
              v-if="promo.status"
              class="text-body-2 ml-4 mr-6"
              style="position: relative">
                <b>Status:</b> {{ promo.status.status.name }}
                <v-btn
                  absolute
                  class="mr-2"
                  style="top: -10px"
                  fab
                  x-small
                  elevation="2"
                  @click="showStatusModal = true">
                  <v-icon small>
                    {{ (readOnlyPromo && !isPromoStatusAdmin) ? 'mdi-view-headline' : 'mdi-pencil' }}
                  </v-icon>
                </v-btn>
            </span>
            <template v-if="promo.audit">
              <span class="ml-6 text-body-2">
                <b>Last updated by:</b> {{ promo.audit.updated_by }}
              </span>
              <span class="ml-4 text-body-2">
                <b>Created On:</b> {{ $config.formatDate(promo.audit.created_on) }}
              </span>
              <span class="ml-4 text-body-2">
                <b>Updated On:</b> {{ $config.formatDate(promo.audit.updated_on) }}
              </span>
            </template>
            <span v-if="promo.cloned_from_promo_id"
              class="ml-4 text-body-2">
              <router-link
                :to="{ name: 'Promo', query: { id: promo.cloned_from_promo_id }}"
                class="primary--text"
                style="text-decoration: none">
                Parent <v-icon small color="primary">mdi-launch</v-icon>
              </router-link>
            </span>
            <div class="mt-4">
              <PromoHeader
                ref="promoTypeFields"
                :promo_type_constant="promo_type_constant"
                :promo="promo"
                :readOnlyPromo="readOnlyPromo"
                :showLumpSum="showLumpSum">
              </PromoHeader>
            </div>
            <v-form ref="form">
            <v-row class="promoRow pt-4">
              <v-col cols="4" class="px-0 py-0">
                <PromoDates
                  ref="promoDates"
                  :promo="promo"
                  :readOnlyPromo="readOnlyPromo">
                </PromoDates>
              </v-col>
              <v-col v-if="promoIsAdCategory" cols="4">
                <v-menu
                  v-model="ad_dt_picker"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="promo.ad_date"
                      label="Ad Date"
                      ref="add_dt"
                      type="date"
                      prepend-inner-icon="mdi-calendar"
                      dense
                      background-color="#fff"
                      outlined
                      :clearable="!readOnlyPromo"
                      :readonly="readOnlyPromo"
                      v-on="!readOnlyPromo ? on : null"
                      v-bind="attrs">
                    </v-text-field>
                  </template>
                  <v-date-picker 
                    v-model="promo.ad_date"
                    color="action"
                    no-title>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4" class="px-1">
                <v-select
                  label="Promotion Type"
                  item-value="id"
                  item-text="name"
                  item-color="action"
                  :items="billingModeOptions"
                  v-model="promo.billing_mode"
                  background-color="#fff"
                  :rules="[isValidBillingMode]"
                  :readonly="readOnlyPromo"
                  dense
                  hide-details="auto"
                  outlined
                  return-object>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="px-1">
                <PromoVendorSelect
                  ref="vendorSelect"
                  :promoBillingParties="promoBillingParties"
                  :readOnlyPromo="readOnlyPromo"
                  :promo="promo"
                  @queueChanges="queueBillingPartyChanges">
                </PromoVendorSelect>
              </v-col>
              <v-col cols="4" class="px-1">
                <PromoRelatedSubvendorSelect
                  ref="subvendorSelect"
                  :promoBillingParties="promoBillingParties"
                  :promoSelectedSubvendors="promoSelectedSubvendors"
                  :readOnlyPromo="readOnlyPromo"
                  :billingPartiesBuffer="billingPartiesBuffer"
                  :allowMultipleSubvendors="allowMultipleSubvendors"
                  @subvendorSelected="handleSubvendorSelected">
                </PromoRelatedSubvendorSelect>
              </v-col>
            </v-row>
            <v-spacer/>
            <v-row v-if="showDigitalCoupon">
              <v-col cols="4" class="px-1">
                <v-text-field
                  v-model="promo.digital_coupon_note"
                  label="Digital Coupon Value"
                  :disabled="!promo.digital_coupon || readOnlyPromo"
                  dense
                  outlined
                  hide-details="auto"
                  background-color="#fff">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="promoRow" align="center">
              <v-col cols="3">
                <v-text-field 
                  v-if="!id"
                  dense
                  outlined
                  hide-details
                  background-color="#fff"
                  label="Promotion Number"
                  v-model="promo.promo_number">
                </v-text-field>
                <span v-else class="text-body-2">
                  <b>Promotion Number:</b> {{ promo.promo_number }}
                </span>
              </v-col>
              <v-col
                v-show="promo.promo_category && promo.promo_category.scan_promotion"
                class="py-0 mt-4">
                <v-layout>
                  <v-checkbox
                    color="checkbox"
                    class="mt-0"
                    height="30px"
                    label="Enforce Compliance"
                    v-model="promo.enforce_compliance"
                    @change="enforceComplianceChanged"
                    :disabled="tenant === 'alliance-retail-group'|| readOnlyPromo" />
                </v-layout>
              </v-col>
              <v-col
                class="py-0 mt-4">
                <v-layout>
                  <v-checkbox
                    color="checkbox"
                    class="mt-0"
                    height="30px"
                    label="Perishable"
                    v-model="promo.perishable"
                    :disabled="readOnlyPromo" />
                </v-layout>
              </v-col>
              <v-col 
                v-if="showDigitalCoupon"
                class="py-0 mt-4">
                <v-layout>
                  <v-checkbox
                    color="checkbox"
                    class="mt-0"
                    height="30px"
                    label="Digital Coupon"
                    v-model="promo.digital_coupon"
                    :disabled="readOnlyPromo" />
                </v-layout>
              </v-col>
              <v-row v-if="!isAMAPorEBA">
                <v-col 
                  v-if="isNonAllowancePromo"
                  class="py-0 mt-4">
                    <v-layout>
                      <v-checkbox
                        color="checkbox"
                        class="mt-0"
                        height="30px"
                        label="Private Label"
                        v-model="promo.private_label"
                        :disabled="readOnlyPromo" />
                    </v-layout>
                </v-col>
                <v-col 
                   v-if="showDSD"
                   class="py-0 mt-4">
                   <v-layout>
                     <v-checkbox
                     color="checkbox"
                     class="mt-0"
                     height="30px"
                     label="DSD"
                     v-model="promo.dsd"
                     :disabled="readOnlyPromo" />
                   </v-layout>
                </v-col>
                <v-col 
                   v-if="showSpecialAdLength"
                   class="py-0 mt-4">
                   <v-layout>
                     <v-checkbox
                      color="checkbox"
                      class="mt-0"
                      height="30px"
                      label="Special Ad Length"
                      v-model="promo.special_ad_length"
                      :disabled="readOnlyPromo">
                    </v-checkbox>
                  </v-layout>
                </v-col>
              </v-row>
              <v-col cols="3"></v-col>
            </v-row>
            <v-row class="mt-8 mr-2">
              <v-spacer />
              <v-tooltip top v-if="showContractDownloadButton">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    elevation="2"
                    class="mr-4"
                    @click="downloadContract">
                    Contract
                    <v-icon class="ml-1">
                      mdi-file-download
                    </v-icon>
                  </v-btn>
                </template>
                <span>Download Contract</span>
              </v-tooltip>
        
              <v-tooltip top v-if="showShuttleButton && !readOnlyPromo">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="#222"
                    elevation="2"
                    class="mr-4 white--text"
                    @click="showShuttleModal = true">
                    Shuttle
                    <v-icon class="ml-1">mdi-bus-multiple</v-icon>
                  </v-btn>
                </template>
                <span>Shuttle Promo</span>
              </v-tooltip>
        
            <v-tooltip top v-if="promo.id">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    elevation="2"
                    class="mr-4"
                    @click="download">
                    Download
                    <v-icon class="ml-1" dense>
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <span>Download Promo</span>
              </v-tooltip>
        
              <v-tooltip top v-if="promo.id">
                <template v-slot:activator="{ on }">
                  <v-btn
                    :disabled="itemsDisabled"
                    v-on="on"
                    elevation="2"
                    class="mr-4"
                    @click="showCloneModal = true">
                    Copy
                    <v-icon class="ml-1" dense>mdi-plus-box-multiple-outline</v-icon>
                  </v-btn>
                </template>
                <span>Copy Promo</span>
              </v-tooltip>

              <v-tooltip top v-if="!readOnlyPromo">
                <template v-slot:activator="{ on }">
                  <v-btn
                    :loading="saving"
                    :disabled="saving"
                    v-on="on"
                    elevation="2"
                    color="green"
                    class="mr-2 white--text"
                    @click="savePromo">
                    {{ (id) ? 'Update' : 'Save' }}
                    <v-icon class="ml-1" dense>
                      {{ (id) ? 'mdi-update' : ' mdi-content-save-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ (id) ? 'Update' : 'Create'}} Promo</span>
              </v-tooltip>

              <v-tooltip top v-if="id && hasScope('promo:delete') && !readOnlyPromo">
                <template v-slot:activator="{ on }">
                  <v-btn
                    elevation="2"
                    v-on="on"
                    color="red"
                    class="white--text mx-1"
                    @click="confirmationModal = true">
                    Delete
                    <v-icon class="ml-1" dense>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete Promo</span>
              </v-tooltip>
            </v-row>
            </v-form>
          </div>
        </span>
        <storeTable
          v-else-if="tab === 1"
          :panel_height="panelHeight - 36.5"
          :promo="promo"
          :readOnlyPromo="readOnlyPromo"
          :partyItemSources="partyItemSources"
          :upstreamPartyIds="upstreamPartyIds"
          :showLumpSum="showLumpSum"
          @enableItemsTab="itemsDisabled = false"
          @setPromoPartyIds="setPromoPartyIds"
          @updateItemSources="updatePartyItemSources"
          @updateShuttleEnabled="(val) => shuttleEnabled = val"
          @setPromoAllowances="(val) => promoAllowances = val"
          @updateHistory="updatePromoHistory">
        </storeTable>
        <itemTable
          v-else
          :panel_height="panelHeight"
          :promo="promo"
          :readOnlyPromo="readOnlyPromo"
          :promo_type_constant="promo_type_constant"
          :item_sources="promoItemSources"
          :promoPartyIds="promoPartyIds"
          :vmc_sources="vmcSources"
          :vendorIds="vendorIds"
          @updateHistory="updatePromoHistory">
        </itemTable>
      </keep-alive>
      <v-dialog v-model="confirmationModal" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Are You Sure?
          </v-card-title>
          <v-card-text>
            Deleting a promo is a permanent action than cannot be undone. Are you sure you want to delete this promo?
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1 white--text" @click="confirmationModal = false">
              No, don't delete!
            </v-btn>
            <v-btn :disabled="deleting" :loading="deleting" color="green darken-1 white--text" @click="deletePromo()">
              Yes, I am sure!
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <CloneModal
        v-model="showCloneModal"
        v-if="showCloneModal"
        :promo="promo"
        @success="initClonedPromo"
        @cancel="showCloneModal = false">
      </CloneModal>
      <ShuttleModal
        v-if="showShuttleModal"
        v-model="showShuttleModal"
        :promoPartyIds="promoPartyIds"
        :partyItemSources="partyItemSources"
        @close="showShuttleModal = false">
      </ShuttleModal>
      <StatusModal
        v-if="showStatusModal"
        v-model="showStatusModal"
        :promo="promo"
        :vendorIds="vendorIds"
        :validLumpSums="validLumpSums"
        :lumpSumTotal="lumpSumTotal"
        :validSubvendorSelected="validSubvendorSelected"
        :validCategoryManagerSelected="validCategoryManagerSelected"
        :isPromoStatusAdmin="isPromoStatusAdmin"
        :readOnlyPromo="readOnlyPromo"
        @close="showStatusModal = false"
        @saved="statusSaved">
      </StatusModal>
    </template>
  </v-container>
</template>
<script>
// Components
import PromoHeader from '@/components/PromoHeader.vue'
import PromoVendorSelect from '@/components/PromoVendorSelect.vue'
import PromoRelatedSubvendorSelect from '@/components/PromoRelatedSubvendorSelect.vue'
import PromoDates from '@/components/PromoDates.vue'
import itemTable from '@/components/items/itemsOnPromoTable.vue'
import storeTable from '@/components/stores/storesOnPromoTable.vue'
const CloneModal = () => import('@/components/CloneModal.vue')
const ShuttleModal = () => import('@/components/ShuttleModal.vue')
const StatusModal = () => import('@/components/StatusModal.vue')

// Mixins
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
import { partySearch } from '@/mixins/party-search'
import { formValidation } from '@/mixins/form-validation'
// promo view component properties have been moved to this mixin so that it may be reused with ARG promo view
import { promoProperties } from '@/mixins/promo'

export default {
  name: 'promo-view-arg',
  data () {
    return {
      panelHeight: 245,
      ad_dt_picker: false,
    }
  },
  computed: {
    promoIsAdCategory() {
      return (this.promo.billing_mode?.constant === 'AD') && this.promo.promo_category?.name?.toLowerCase()?.includes('ad')
    },
    showDigitalCoupon () {
      return this.promoIsAdCategory
    },
    showSpecialAdLength () {
      return (this.promo?.promo_category?.billback_promotion)
        && (this.promoIsAdCategory && !['Ad Billback - 1-2 Day Ad', 'Ad Billback Non-Allowance'].includes(this.promo.promo_category.name))
    },
    showDSD () {
      return (this.promo?.promo_category?.scan_promotion || this.promo.promo_category?.name === 'DSD Off Invoice')
    }
  },
  components: {
    itemTable,
    storeTable,
    CloneModal,
    ShuttleModal,
    StatusModal,
    PromoHeader,
    PromoVendorSelect,
    PromoRelatedSubvendorSelect,
    PromoDates
  },
  mixins: [displayAlert, utils, userAccess, partySearch, promoProperties, formValidation]
}
</script>
<style scoped>
.active {
  background: #fff;
  color: #37474F !important;
}
</style>