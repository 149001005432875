var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      [
        _c(
          "v-tabs",
          {
            attrs: {
              grow: "",
              dark: "",
              "background-color": "primary",
              "hide-slider": "",
              "active-class": "active"
            },
            model: {
              value: _vm.tab,
              callback: function($$v) {
                _vm.tab = $$v
              },
              expression: "tab"
            }
          },
          [
            _c("v-tab", [_vm._v("Promotion Details")]),
            _c("v-tab", { attrs: { disabled: !_vm.existingPromo } }, [
              _vm._v("Group / Store")
            ]),
            _c(
              "v-tab",
              { attrs: { disabled: !_vm.existingPromo || _vm.itemsDisabled } },
              [_vm._v("Items")]
            )
          ],
          1
        ),
        _c(
          "keep-alive",
          [
            _vm.tab === 0
              ? _c("span", [
                  _c(
                    "div",
                    { staticClass: "mt-4 ml-4" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "primary--text",
                          staticStyle: { "text-decoration": "none" },
                          attrs: { to: { path: "/promos" } }
                        },
                        [
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v("mdi-chevron-left")
                          ]),
                          _vm._v("Return to Promotions ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mx-8 mt-4" },
                    [
                      _c("span", { staticClass: "text-body-2" }, [
                        _c("b", [_vm._v("Promotion Category:")]),
                        _vm._v(" " + _vm._s(_vm.promoTypeDisplayName) + " ")
                      ]),
                      _vm.promo.status
                        ? _c(
                            "span",
                            {
                              staticClass: "text-body-2 ml-4 mr-6",
                              staticStyle: { position: "relative" }
                            },
                            [
                              _c("b", [_vm._v("Status:")]),
                              _vm._v(
                                " " + _vm._s(_vm.promo.status.status.name) + " "
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  staticStyle: { top: "-10px" },
                                  attrs: {
                                    absolute: "",
                                    fab: "",
                                    "x-small": "",
                                    elevation: "2"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.showStatusModal = true
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.readOnlyPromo &&
                                            !_vm.isPromoStatusAdmin
                                            ? "mdi-view-headline"
                                            : "mdi-pencil"
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.promo.audit
                        ? [
                            _c("span", { staticClass: "ml-6 text-body-2" }, [
                              _c("b", [_vm._v("Last updated by:")]),
                              _vm._v(
                                " " + _vm._s(_vm.promo.audit.updated_by) + " "
                              )
                            ]),
                            _c("span", { staticClass: "ml-4 text-body-2" }, [
                              _c("b", [_vm._v("Created On:")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$config.formatDate(
                                      _vm.promo.audit.created_on
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c("span", { staticClass: "ml-4 text-body-2" }, [
                              _c("b", [_vm._v("Updated On:")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$config.formatDate(
                                      _vm.promo.audit.updated_on
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        : _vm._e(),
                      _vm.promo.cloned_from_promo_id
                        ? _c(
                            "span",
                            { staticClass: "ml-4 text-body-2" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "primary--text",
                                  staticStyle: { "text-decoration": "none" },
                                  attrs: {
                                    to: {
                                      name: "Promo",
                                      query: {
                                        id: _vm.promo.cloned_from_promo_id
                                      }
                                    }
                                  }
                                },
                                [
                                  _vm._v(" Parent "),
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "primary" } },
                                    [_vm._v("mdi-launch")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c("PromoHeader", {
                            ref: "promoTypeFields",
                            attrs: {
                              promo_type_constant: _vm.promo_type_constant,
                              promo: _vm.promo,
                              readOnlyPromo: _vm.readOnlyPromo,
                              showLumpSum: _vm.showLumpSum
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-form",
                        { ref: "form" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "promoRow pt-4" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "px-0 py-0",
                                  attrs: { cols: "4" }
                                },
                                [
                                  _c("PromoDates", {
                                    ref: "promoDates",
                                    attrs: {
                                      promo: _vm.promo,
                                      readOnlyPromo: _vm.readOnlyPromo
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.promoIsAdCategory
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "max-width": "290px",
                                            "min-width": "290px"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            ref: "add_dt",
                                                            attrs: {
                                                              label: "Ad Date",
                                                              type: "date",
                                                              "prepend-inner-icon":
                                                                "mdi-calendar",
                                                              dense: "",
                                                              "background-color":
                                                                "#fff",
                                                              outlined: "",
                                                              clearable: !_vm.readOnlyPromo,
                                                              readonly:
                                                                _vm.readOnlyPromo
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.promo
                                                                  .ad_date,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.promo,
                                                                  "ad_date",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "promo.ad_date"
                                                            }
                                                          },
                                                          "v-text-field",
                                                          attrs,
                                                          false
                                                        ),
                                                        !_vm.readOnlyPromo
                                                          ? on
                                                          : null
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2455149598
                                          ),
                                          model: {
                                            value: _vm.ad_dt_picker,
                                            callback: function($$v) {
                                              _vm.ad_dt_picker = $$v
                                            },
                                            expression: "ad_dt_picker"
                                          }
                                        },
                                        [
                                          _c("v-date-picker", {
                                            attrs: {
                                              color: "action",
                                              "no-title": ""
                                            },
                                            model: {
                                              value: _vm.promo.ad_date,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.promo,
                                                  "ad_date",
                                                  $$v
                                                )
                                              },
                                              expression: "promo.ad_date"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                { staticClass: "px-1", attrs: { cols: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Promotion Type",
                                      "item-value": "id",
                                      "item-text": "name",
                                      "item-color": "action",
                                      items: _vm.billingModeOptions,
                                      "background-color": "#fff",
                                      rules: [_vm.isValidBillingMode],
                                      readonly: _vm.readOnlyPromo,
                                      dense: "",
                                      "hide-details": "auto",
                                      outlined: "",
                                      "return-object": ""
                                    },
                                    model: {
                                      value: _vm.promo.billing_mode,
                                      callback: function($$v) {
                                        _vm.$set(_vm.promo, "billing_mode", $$v)
                                      },
                                      expression: "promo.billing_mode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "px-1", attrs: { cols: "4" } },
                                [
                                  _c("PromoVendorSelect", {
                                    ref: "vendorSelect",
                                    attrs: {
                                      promoBillingParties:
                                        _vm.promoBillingParties,
                                      readOnlyPromo: _vm.readOnlyPromo,
                                      promo: _vm.promo
                                    },
                                    on: {
                                      queueChanges: _vm.queueBillingPartyChanges
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "px-1", attrs: { cols: "4" } },
                                [
                                  _c("PromoRelatedSubvendorSelect", {
                                    ref: "subvendorSelect",
                                    attrs: {
                                      promoBillingParties:
                                        _vm.promoBillingParties,
                                      promoSelectedSubvendors:
                                        _vm.promoSelectedSubvendors,
                                      readOnlyPromo: _vm.readOnlyPromo,
                                      billingPartiesBuffer:
                                        _vm.billingPartiesBuffer,
                                      allowMultipleSubvendors:
                                        _vm.allowMultipleSubvendors
                                    },
                                    on: {
                                      subvendorSelected:
                                        _vm.handleSubvendorSelected
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _vm.showDigitalCoupon
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "px-1",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Digital Coupon Value",
                                          disabled:
                                            !_vm.promo.digital_coupon ||
                                            _vm.readOnlyPromo,
                                          dense: "",
                                          outlined: "",
                                          "hide-details": "auto",
                                          "background-color": "#fff"
                                        },
                                        model: {
                                          value: _vm.promo.digital_coupon_note,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.promo,
                                              "digital_coupon_note",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "promo.digital_coupon_note"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            {
                              staticClass: "promoRow",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  !_vm.id
                                    ? _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          "hide-details": "",
                                          "background-color": "#fff",
                                          label: "Promotion Number"
                                        },
                                        model: {
                                          value: _vm.promo.promo_number,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.promo,
                                              "promo_number",
                                              $$v
                                            )
                                          },
                                          expression: "promo.promo_number"
                                        }
                                      })
                                    : _c(
                                        "span",
                                        { staticClass: "text-body-2" },
                                        [
                                          _c("b", [
                                            _vm._v("Promotion Number:")
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.promo.promo_number) +
                                              " "
                                          )
                                        ]
                                      )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.promo.promo_category &&
                                        _vm.promo.promo_category.scan_promotion,
                                      expression:
                                        "promo.promo_category && promo.promo_category.scan_promotion"
                                    }
                                  ],
                                  staticClass: "py-0 mt-4"
                                },
                                [
                                  _c(
                                    "v-layout",
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          color: "checkbox",
                                          height: "30px",
                                          label: "Enforce Compliance",
                                          disabled:
                                            _vm.tenant ===
                                              "alliance-retail-group" ||
                                            _vm.readOnlyPromo
                                        },
                                        on: {
                                          change: _vm.enforceComplianceChanged
                                        },
                                        model: {
                                          value: _vm.promo.enforce_compliance,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.promo,
                                              "enforce_compliance",
                                              $$v
                                            )
                                          },
                                          expression: "promo.enforce_compliance"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "py-0 mt-4" },
                                [
                                  _c(
                                    "v-layout",
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          color: "checkbox",
                                          height: "30px",
                                          label: "Perishable",
                                          disabled: _vm.readOnlyPromo
                                        },
                                        model: {
                                          value: _vm.promo.perishable,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.promo,
                                              "perishable",
                                              $$v
                                            )
                                          },
                                          expression: "promo.perishable"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.showDigitalCoupon
                                ? _c(
                                    "v-col",
                                    { staticClass: "py-0 mt-4" },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "mt-0",
                                            attrs: {
                                              color: "checkbox",
                                              height: "30px",
                                              label: "Digital Coupon",
                                              disabled: _vm.readOnlyPromo
                                            },
                                            model: {
                                              value: _vm.promo.digital_coupon,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.promo,
                                                  "digital_coupon",
                                                  $$v
                                                )
                                              },
                                              expression: "promo.digital_coupon"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.isAMAPorEBA
                                ? _c(
                                    "v-row",
                                    [
                                      _vm.isNonAllowancePromo
                                        ? _c(
                                            "v-col",
                                            { staticClass: "py-0 mt-4" },
                                            [
                                              _c(
                                                "v-layout",
                                                [
                                                  _c("v-checkbox", {
                                                    staticClass: "mt-0",
                                                    attrs: {
                                                      color: "checkbox",
                                                      height: "30px",
                                                      label: "Private Label",
                                                      disabled:
                                                        _vm.readOnlyPromo
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.promo.private_label,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.promo,
                                                          "private_label",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "promo.private_label"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.showDSD
                                        ? _c(
                                            "v-col",
                                            { staticClass: "py-0 mt-4" },
                                            [
                                              _c(
                                                "v-layout",
                                                [
                                                  _c("v-checkbox", {
                                                    staticClass: "mt-0",
                                                    attrs: {
                                                      color: "checkbox",
                                                      height: "30px",
                                                      label: "DSD",
                                                      disabled:
                                                        _vm.readOnlyPromo
                                                    },
                                                    model: {
                                                      value: _vm.promo.dsd,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.promo,
                                                          "dsd",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "promo.dsd"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.showSpecialAdLength
                                        ? _c(
                                            "v-col",
                                            { staticClass: "py-0 mt-4" },
                                            [
                                              _c(
                                                "v-layout",
                                                [
                                                  _c("v-checkbox", {
                                                    staticClass: "mt-0",
                                                    attrs: {
                                                      color: "checkbox",
                                                      height: "30px",
                                                      label:
                                                        "Special Ad Length",
                                                      disabled:
                                                        _vm.readOnlyPromo
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.promo
                                                          .special_ad_length,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.promo,
                                                          "special_ad_length",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "promo.special_ad_length"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("v-col", { attrs: { cols: "3" } })
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "mt-8 mr-2" },
                            [
                              _c("v-spacer"),
                              _vm.showContractDownloadButton
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mr-4",
                                                      attrs: { elevation: "2" },
                                                      on: {
                                                        click:
                                                          _vm.downloadContract
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(" Contract "),
                                                    _c(
                                                      "v-icon",
                                                      { staticClass: "ml-1" },
                                                      [
                                                        _vm._v(
                                                          " mdi-file-download "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1590734062
                                      )
                                    },
                                    [_c("span", [_vm._v("Download Contract")])]
                                  )
                                : _vm._e(),
                              _vm.showShuttleButton && !_vm.readOnlyPromo
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "mr-4 white--text",
                                                      attrs: {
                                                        color: "#222",
                                                        elevation: "2"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.showShuttleModal = true
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(" Shuttle "),
                                                    _c(
                                                      "v-icon",
                                                      { staticClass: "ml-1" },
                                                      [
                                                        _vm._v(
                                                          "mdi-bus-multiple"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2471820082
                                      )
                                    },
                                    [_c("span", [_vm._v("Shuttle Promo")])]
                                  )
                                : _vm._e(),
                              _vm.promo.id
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mr-4",
                                                      attrs: { elevation: "2" },
                                                      on: {
                                                        click: _vm.download
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(" Download "),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-1",
                                                        attrs: { dense: "" }
                                                      },
                                                      [_vm._v(" mdi-download ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        705292994
                                      )
                                    },
                                    [_c("span", [_vm._v("Download Promo")])]
                                  )
                                : _vm._e(),
                              _vm.promo.id
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mr-4",
                                                      attrs: {
                                                        disabled:
                                                          _vm.itemsDisabled,
                                                        elevation: "2"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.showCloneModal = true
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(" Copy "),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-1",
                                                        attrs: { dense: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-plus-box-multiple-outline"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1295452148
                                      )
                                    },
                                    [_c("span", [_vm._v("Copy Promo")])]
                                  )
                                : _vm._e(),
                              !_vm.readOnlyPromo
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "mr-2 white--text",
                                                      attrs: {
                                                        loading: _vm.saving,
                                                        disabled: _vm.saving,
                                                        elevation: "2",
                                                        color: "green"
                                                      },
                                                      on: {
                                                        click: _vm.savePromo
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.id
                                                            ? "Update"
                                                            : "Save"
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-1",
                                                        attrs: { dense: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.id
                                                                ? "mdi-update"
                                                                : " mdi-content-save-outline"
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3732011782
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.id ? "Update" : "Create") +
                                            " Promo"
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm.id &&
                              _vm.hasScope("promo:delete") &&
                              !_vm.readOnlyPromo
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "white--text mx-1",
                                                      attrs: {
                                                        elevation: "2",
                                                        color: "red"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.confirmationModal = true
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(" Delete "),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-1",
                                                        attrs: { dense: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-trash-can-outline"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1104058608
                                      )
                                    },
                                    [_c("span", [_vm._v("Delete Promo")])]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ])
              : _vm.tab === 1
              ? _c("storeTable", {
                  attrs: {
                    panel_height: _vm.panelHeight - 36.5,
                    promo: _vm.promo,
                    readOnlyPromo: _vm.readOnlyPromo,
                    partyItemSources: _vm.partyItemSources,
                    upstreamPartyIds: _vm.upstreamPartyIds,
                    showLumpSum: _vm.showLumpSum
                  },
                  on: {
                    enableItemsTab: function($event) {
                      _vm.itemsDisabled = false
                    },
                    setPromoPartyIds: _vm.setPromoPartyIds,
                    updateItemSources: _vm.updatePartyItemSources,
                    updateShuttleEnabled: function(val) {
                      return (_vm.shuttleEnabled = val)
                    },
                    setPromoAllowances: function(val) {
                      return (_vm.promoAllowances = val)
                    },
                    updateHistory: _vm.updatePromoHistory
                  }
                })
              : _c("itemTable", {
                  attrs: {
                    panel_height: _vm.panelHeight,
                    promo: _vm.promo,
                    readOnlyPromo: _vm.readOnlyPromo,
                    promo_type_constant: _vm.promo_type_constant,
                    item_sources: _vm.promoItemSources,
                    promoPartyIds: _vm.promoPartyIds,
                    vmc_sources: _vm.vmcSources,
                    vendorIds: _vm.vendorIds
                  },
                  on: { updateHistory: _vm.updatePromoHistory }
                })
          ],
          1
        ),
        _c(
          "v-dialog",
          {
            attrs: { width: "500" },
            model: {
              value: _vm.confirmationModal,
              callback: function($$v) {
                _vm.confirmationModal = $$v
              },
              expression: "confirmationModal"
            }
          },
          [
            _c(
              "v-card",
              [
                _c("v-card-title", { staticClass: "headline grey lighten-2" }, [
                  _vm._v(" Are You Sure? ")
                ]),
                _c("v-card-text", [
                  _vm._v(
                    " Deleting a promo is a permanent action than cannot be undone. Are you sure you want to delete this promo? "
                  )
                ]),
                _c("v-divider"),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "red darken-1 white--text" },
                        on: {
                          click: function($event) {
                            _vm.confirmationModal = false
                          }
                        }
                      },
                      [_vm._v(" No, don't delete! ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: _vm.deleting,
                          loading: _vm.deleting,
                          color: "green darken-1 white--text"
                        },
                        on: {
                          click: function($event) {
                            return _vm.deletePromo()
                          }
                        }
                      },
                      [_vm._v(" Yes, I am sure! ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm.showCloneModal
          ? _c("CloneModal", {
              attrs: { promo: _vm.promo },
              on: {
                success: _vm.initClonedPromo,
                cancel: function($event) {
                  _vm.showCloneModal = false
                }
              },
              model: {
                value: _vm.showCloneModal,
                callback: function($$v) {
                  _vm.showCloneModal = $$v
                },
                expression: "showCloneModal"
              }
            })
          : _vm._e(),
        _vm.showShuttleModal
          ? _c("ShuttleModal", {
              attrs: {
                promoPartyIds: _vm.promoPartyIds,
                partyItemSources: _vm.partyItemSources
              },
              on: {
                close: function($event) {
                  _vm.showShuttleModal = false
                }
              },
              model: {
                value: _vm.showShuttleModal,
                callback: function($$v) {
                  _vm.showShuttleModal = $$v
                },
                expression: "showShuttleModal"
              }
            })
          : _vm._e(),
        _vm.showStatusModal
          ? _c("StatusModal", {
              attrs: {
                promo: _vm.promo,
                vendorIds: _vm.vendorIds,
                validLumpSums: _vm.validLumpSums,
                lumpSumTotal: _vm.lumpSumTotal,
                validSubvendorSelected: _vm.validSubvendorSelected,
                validCategoryManagerSelected: _vm.validCategoryManagerSelected,
                isPromoStatusAdmin: _vm.isPromoStatusAdmin,
                readOnlyPromo: _vm.readOnlyPromo
              },
              on: {
                close: function($event) {
                  _vm.showStatusModal = false
                },
                saved: _vm.statusSaved
              },
              model: {
                value: _vm.showStatusModal,
                callback: function($$v) {
                  _vm.showStatusModal = $$v
                },
                expression: "showStatusModal"
              }
            })
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }