var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form", staticClass: "ma-0 pa-0", attrs: { "lazy-validation": "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0 pa-0" },
        [
          _c(
            "v-col",
            { staticClass: "px-1" },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    transition: "scale-transition",
                    "offset-y": "",
                    "nudge-top": _vm.hideDetails ? null : "25",
                    "max-width": "290px",
                    "min-width": "290px"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  ref: "start_dt",
                                  attrs: {
                                    label: "Start Date",
                                    type: "date",
                                    "prepend-inner-icon": "mdi-calendar",
                                    dense: "",
                                    "background-color": "#fff",
                                    outlined: "",
                                    clearable: !_vm.readOnlyPromo,
                                    readonly: _vm.readOnlyPromo,
                                    "hide-details": _vm.hideDetails,
                                    rules: [_vm.isValidStartDate],
                                    error: _vm.startDateErr
                                  },
                                  on: {
                                    input: function($event) {
                                      _vm.promo.end_dt
                                        ? _vm.$refs.end_dt.validate()
                                        : null
                                    }
                                  },
                                  model: {
                                    value: _vm.promo.start_dt,
                                    callback: function($$v) {
                                      _vm.$set(_vm.promo, "start_dt", $$v)
                                    },
                                    expression: "promo.start_dt"
                                  }
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              !_vm.readOnlyPromo ? on : null
                            )
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.start_dt_picker,
                    callback: function($$v) {
                      _vm.start_dt_picker = $$v
                    },
                    expression: "start_dt_picker"
                  }
                },
                [
                  _c("v-date-picker", {
                    attrs: {
                      color: "action",
                      max: _vm.promo.end_dt,
                      "show-current": _vm.promo.end_dt || true,
                      "allowed-dates": _vm.allowedStartDates,
                      "no-title": ""
                    },
                    model: {
                      value: _vm.promo.start_dt,
                      callback: function($$v) {
                        _vm.$set(_vm.promo, "start_dt", $$v)
                      },
                      expression: "promo.start_dt"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-1" },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    transition: "scale-transition",
                    "offset-y": "",
                    "nudge-top": _vm.hideDetails ? null : "25",
                    "max-width": "290px",
                    "min-width": "290px"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  ref: "end_dt",
                                  attrs: {
                                    label: "End Date",
                                    type: "date",
                                    "prepend-inner-icon": "mdi-calendar",
                                    dense: "",
                                    "background-color": "#fff",
                                    outlined: "",
                                    clearable: !_vm.readOnlyPromo,
                                    readonly: _vm.readOnlyPromo,
                                    "hide-details": _vm.hideDetails,
                                    rules: [_vm.isValidEndDate]
                                  },
                                  on: {
                                    input: function($event) {
                                      _vm.promo.start_dt
                                        ? _vm.$refs.start_dt.validate()
                                        : null
                                    }
                                  },
                                  model: {
                                    value: _vm.promo.end_dt,
                                    callback: function($$v) {
                                      _vm.$set(_vm.promo, "end_dt", $$v)
                                    },
                                    expression: "promo.end_dt"
                                  }
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              !_vm.readOnlyPromo ? on : null
                            )
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.end_dt_picker,
                    callback: function($$v) {
                      _vm.end_dt_picker = $$v
                    },
                    expression: "end_dt_picker"
                  }
                },
                [
                  _c("v-date-picker", {
                    attrs: {
                      color: "action",
                      min: _vm.promo.start_dt,
                      "show-current": _vm.promo.start_dt || true,
                      "allowed-dates": _vm.allowedEndDates,
                      "no-title": ""
                    },
                    model: {
                      value: _vm.promo.end_dt,
                      callback: function($$v) {
                        _vm.$set(_vm.promo, "end_dt", $$v)
                      },
                      expression: "promo.end_dt"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }