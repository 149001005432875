var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        "nudge-bottom": 5,
        "close-on-content-click": false
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "ml-auto text-capitalize",
                    attrs: { rounded: "", text: "", color: "grey darken-2" }
                  },
                  on
                ),
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-filter-outline")
                  ]),
                  _vm._v(" Status "),
                  _c("v-badge", {
                    staticClass: "ml-1 mb-2",
                    attrs: {
                      color: "accent",
                      content: _vm.filterCount,
                      value: _vm.filterCount > 0
                    }
                  })
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.filterMenu,
        callback: function($$v) {
          _vm.filterMenu = $$v
        },
        expression: "filterMenu"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { width: "350" } },
        [
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "", justify: "center" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "ma-0 pa-0" },
                      [
                        _c("v-select", {
                          attrs: {
                            value: _vm.value,
                            label: "Promo Status Filter",
                            items: _vm.promo_statuses,
                            "item-text": "name",
                            "item-value": "id",
                            "menu-props": { offsetY: true },
                            multiple: "",
                            chips: "",
                            clearable: "",
                            disabled: _vm.loading,
                            "background-color": "#fff",
                            "item-color": "action",
                            dense: "",
                            "hide-details": "",
                            outlined: ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.$emit("update:value", $event)
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "selection",
                              fn: function(ref) {
                                var item = ref.item
                                var index = ref.index
                                return [
                                  index === 0
                                    ? _c("v-chip", { attrs: { small: "" } }, [
                                        _c("span", [_vm._v(_vm._s(item.name))])
                                      ])
                                    : _vm._e(),
                                  index === 1
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "grey--text text-caption"
                                        },
                                        [
                                          _vm._v(
                                            " (+" +
                                              _vm._s(_vm.value.length - 1) +
                                              ") "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }