<template>
  <v-container fluid class="ma-0 pa-0">
    <v-autocomplete
      v-model="localBillingParties"
      :items="vendors"
      item-text="display_name"
      item-value="party_id"
      item-color="action"
      label="Vendor"
      :disabled="readOnlyPromo"
      :loading="loadingVendors"
      :search-input.sync="vendorSearch"
      background-color="#fff"
      :error-messages="billingPartyErrors"
      @blur="queueBillingPartyChanges"
      hide-no-data
      multiple
      cache-items
      return-object
      dense
      outlined
      hide-details="auto">
      <template v-slot:selection="{ item, index }">
        <v-chip
          small
          v-if="index === 0"
          :style="localBillingParties.length > 1 ? 'max-width: 70%' : 'max-width: 90%'">
          <span class="text-truncate">
            {{ `${item.display_name}` }}
          </span>
        </v-chip>
        <span
          v-if="index === 1"
          style="max-width: 20%;"
          class="grey--text text-caption text-truncate">
          (+{{ localBillingParties.length - 1 }})
        </span>
      </template>
    </v-autocomplete>
    <v-dialog
      justify="center"
      v-model="showDialog"
      persistent
      width="600">
      <v-card flat>
        <v-card-title>Confirm Changes</v-card-title>
        <v-alert v-model="showDialog" text type="warning">
          <p>
            You've removed the following vendors:<br />
            <strong>{{ removedVendors.join(', ') }}</strong>
          </p>
          <p>
            If you continue, any existing items associated with these vendors will be removed from this promotion.
          </p>
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-4" @click="cancelChanges">
            Cancel
          </v-btn>
          <v-btn color="red" class="white--text" @click="confirmChanges">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
// api
import Search from '@/axios/search-endpoint'
// mixins
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
// third-party
import { cloneDeep, debounce } from 'lodash'
export default {
  data () {
    return {
      localBillingParties: [],
      vendors: [],
      vendorSearch: '',
      loadingVendors: false,
      billingPartyErrors: [],
      showDialog: false
    }
  },
  name: 'PromoVendorSelect',
  mixins: [displayAlert, utils, userAccess],
  props: {
    promoBillingParties: Array,
    promo: Object,
    readOnlyPromo: Boolean
  },
  watch: {
    vendorSearch: {
      handler: debounce(function(newValue) {
        if (newValue) {
          this.searchVendors()
        }
      }, 500)
    },
    promoBillingParties: {
      handler () {
        this.initVendors()
      },
      deep: true
    },
    'promo.private_label': {
      handler (newValue, oldValue) {
        if (newValue != oldValue && this.localBillingParties.length > 1) {
        this.queueBillingPartyChanges()
        }
      }
    },
    relatedVendorPartyIds: {
      handler (newValue) {
        if (newValue && this.limitAccessByRelatedVendors) {
          this.searchVendors()
        }
      },
      deep: true
    }
  },
  created () {
    if (this.promoBillingParties.length > 0) {
      this.initVendors()
    }
    if (this.limitAccessByRelatedVendors) {
      this.searchVendors()
    }
  },
  computed: {
    addedBillingParties() {
      return this.localBillingParties.filter(party => {
        const partyExistsOnPromo = this.partyOnList(this.promoBillingParties, party)
        return !partyExistsOnPromo
      })
    },
    removedBillingParties() {
      return this.promoBillingParties.filter(party => {
        const partyExistsLocally = this.partyOnList(this.localBillingParties, party)
        return !partyExistsLocally
      })
    },
    billingPartiesChanges () {
      return {
        added: this.addedBillingParties,
        removed: this.removedBillingParties,
        included: this.localBillingParties
      }
    },
    removedVendors () {
      if (this.removedBillingParties.length > 0) {
        return this.removedBillingParties.map(party => {
          return `${party.vendor_id} ${party.name}`
        })
      }
      return []
    }
  },
  methods: {
    initVendors () {
      this.localBillingParties = cloneDeep(this.promoBillingParties)
      this.vendors = [...this.localBillingParties]
    },
    async searchVendors () {
      if (this.limitAccessByRelatedVendors && this.relatedVendorPartyIds.length === 0) return
      if (this.vendorSearch) this.loadingVendors = true
      let vendors = []
      const { term, operator } = this.buildVendorSearchParams()
      try {
        let { data } = await Search.customerSearch(term, operator)
        if (this.limitAccessByRelatedVendors) {
          data = data.filter(p => this.relatedVendorPartyIds.includes(p?.party_id))
        }
        if (data?.length > 0) {
          vendors = data.flatMap(party => {
            if (party.party_type === 'VENDOR') {
              party.name = party.party_name
              party.vendor_id = party?.attributes?.VENDOR_ID || ''
              party.display_name = `${party.vendor_id} ${party.name}`
              return party
            }
            return []
          })
        }
        this.vendors = [...this.vendors, ...vendors]
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loadingVendors = false
      }
    },
    buildVendorSearchParams () {
      let term = 'VENDOR'
      let operator = 'and'
      if (this.vendorSearch) {
        term = this.vendorSearch
      } else if (this.limitAccessByRelatedVendors) {
        term = this.relatedVendorPartyIds.join(' ')
        operator = 'or'
      }
      return { term, operator }
    },
    confirmChanges () {
      this.showDialog = false
      this.$emit('queueChanges', this.billingPartiesChanges)
    },
    cancelChanges () {
      this.showDialog = false
      this.initVendors()
    },
    partyOnList(listToSearch, partyToSearch) {
      const result = listToSearch?.find(partyOnList => partyOnList.party_id === partyToSearch.party_id)
      return result
    },
    validate () {
      if (this.tenantRequiresVendor) {
        if (this.localBillingParties.length === 0) {
          this.billingPartyErrors = ['At least one vendor must be selected']
          return false
        }
      if (this.tenant === 'alliance-retail-group') {
        if (this.localBillingParties.length > 1 && this.promo.private_label === false && !['BUY_X_GET_X', 'BUY_X_SAVE_$Y'].includes(this.promo.promotion_type.constant)) {
          this.billingPartyErrors = ['Multiple vendors selected.']
          return false
        }  
      }
        this.billingPartyErrors = []
      }
      return true
    },
    queueBillingPartyChanges () {
      if (this.tenantRequiresVendor) {
        const valid = this.validate()
        if (!valid) return
        if (this.$route.query?.id && this.removedVendors.length > 0) {
          return this.showDialog = true
        }
      }
      this.$emit('queueChanges', this.billingPartiesChanges)
    }
  }
}
</script>