import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class ContractUpload {
  constructor () {
    this.headers = {
      'Content-type': 'application/json'
    }
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        ...this.headers
      }
    })
    this.contractUpload = base
  }

  async getList (searchParams = {}, offset = 0, limit = 200) {
    await AuthToken.setAuth(this.contractUpload)
    return this.contractUpload({
      url: '/contract/uploads',
      method: 'GET',
      params: { ...searchParams, offset, limit }
    })
  }

  async get (uploadId) {
    await AuthToken.setAuth(this.contractUpload)
    return this.contractUpload({
      url: `/contract/upload/${uploadId}`,
      method: 'GET'
    })
  }

  async uploadContract(data) {
    await AuthToken.setAuth(this.contractUpload)
    return this.contractUpload({
      url: '/contract/upload',
      method: 'POST',
      headers: {
        ...this.headers,
        'Content-Type': 'multipart/form-data'
      },
      data
    })
  }

  async rerunContractUpload (data, uploadId) {
    await AuthToken.setAuth(this.contractUpload)
    return this.contractUpload({
      url: `/contract/upload/${uploadId}`,
      method: 'PUT',
      headers: {
        ...this.headers,
        'Content-Type': 'multipart/form-data'
      },
      data
    })
  }

  async downloadContract (data) {
    await AuthToken.setAuth(this.contractUpload)
    return this.contractUpload({
      url: '/contract/download',
      method: 'GET',
      params: data
    })
  }

  async downloadWarningLog (uploadId) {
    await AuthToken.setAuth(this.contractUpload)
    return this.contractUpload({
      url: `/contract/download/warning_log/${uploadId}`,
      method: 'GET'
    })
  }

  async cancelContractUpload (uploadId) {
    await AuthToken.setAuth(this.contractUpload)
    return this.contractUpload({
      url: `/contract/cancel/${uploadId}`,
      method: 'PUT'
    })
  }

  async downloadTemplate () {
    await AuthToken.setAuth(this.contractUpload)
    return this.contractUpload({
      url: '/contract/download/template',
      method: 'GET'
    })
  }

  async finalizeUpload(uploadId) {
    await AuthToken.setAuth(this.contractUpload)
    return this.contractUpload({
      url: `/contract/finalize/${uploadId}`,
      method: 'PUT'
    })
  }

  async getExceptionItems (uploadId) {
    await AuthToken.setAuth(this.contractUpload)
    return this.contractUpload({
      url: `/contract/exception_items/${uploadId}`,
      method: 'GET'
    })
  }

  async getExceptionReport (params) {
    await AuthToken.setAuth(this.contractUpload)
    return this.contractUpload({
      url: `/contract/reports/exception`,
      method: 'GET',
      params: params
    })
  }

  async getMissingItemsReport (params) {
    await AuthToken.setAuth(this.contractUpload)
    return this.contractUpload({
      url: `/contract/reports/missing_items`,
      method: 'GET',
      params: params
    })
  }
}
export default new ContractUpload()
