import Vue from 'vue'
import VueRouter from 'vue-router'
import Promos from '../views/Promos.vue'
import PromoView from '../views/PromoView.vue'
const PromoItems = () => import('@/views/PromoItems.vue')
const ContractUpload = () => import('@/views/ContractUpload.vue')

// authentication guard
import { authGuard } from '@/auth/authGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Promos,
    meta: {
      title: 'Promos',
      requiresAuth: true
    }
  },
  {
    path: '/promos',
    name: 'Promos',
    component: Promos,
    meta: {
      title: 'Promos',
      requiresAuth: true
    }
  },
  {
    path: '/promo',
    name: 'Promo',
    component: PromoView,
    props: true,
    meta: {
      title: 'Promos',
      requiresAuth: true
    }
  },
  {
    path: '/contract-upload',
    name: 'ContractUpload',
    component: ContractUpload,
    meta: {
      title: 'Contract Upload',
      requiresAuth: true
    }
  },
  {
    path: '/promo-items',
    name: 'Promo Items',
    component: PromoItems,
    meta: {
      title: 'Promo Items',
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve(async (to, from, next) => {
  to.meta.referrer = from.name
  if (to.meta) {
    // Use the route's "meta.title" value to assign the page's title
    if (to.meta.title) document.title = to.meta.title
    // For routes requiring authentication ( has "meta.requiresAuth" property )
    if (to.meta.requiresAuth === true) {
      return authGuard(to, from, next)
    }
    return next()
  } else {
    return next()
  }
})

export default router
