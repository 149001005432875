<template>
  <span>
    <Promo v-if="tenant !== 'alliance-retail-group'" />
    <PromoArg v-else />
  </span>
</template>
<script>
// Components
import Promo from '@/views/Promo.vue'
import PromoArg from '@/views/PromoARG.vue'
// mixins
import { userAccess } from '@/mixins/user-access'
export default {
  name: 'promo-view',
  components: {
    Promo,
    PromoArg
  },
  mixins: [userAccess],
}
</script>