var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "", align: "center" } },
        [
          _c(
            "v-toolbar",
            { staticClass: "grey lighten-3", attrs: { flat: "" } },
            [
              _c("v-toolbar-title", [_vm._v(" Promotions ")]),
              _vm.$auth.tenant === "alliance-retail-group"
                ? [
                    _c(
                      "v-col",
                      { staticClass: "pr-4", attrs: { cols: "auto" } },
                      [
                        _c("v-switch", {
                          attrs: {
                            color: "action",
                            dense: "",
                            disabled: _vm.loading,
                            "hide-details": ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          color: _vm.showGroupedPromos
                                                            ? "action"
                                                            : undefined
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        " mdi-file-document-multiple-outline "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1826901580
                                        )
                                      },
                                      [_vm._v(" Group by Contract File ")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            2749159704
                          ),
                          model: {
                            value: _vm.showGroupedPromos,
                            callback: function($$v) {
                              _vm.showGroupedPromos = $$v
                            },
                            expression: "showGroupedPromos"
                          }
                        })
                      ],
                      1
                    ),
                    _c("v-divider", { attrs: { vertical: "", inset: "" } }),
                    _c(
                      "v-col",
                      { staticClass: "pl-1", attrs: { cols: "auto" } },
                      [
                        _c("PromoStatusFilter", {
                          attrs: {
                            value: _vm.selectedStatuses,
                            loading: _vm.loading
                          },
                          on: {
                            "update:value": function($event) {
                              _vm.selectedStatuses = $event
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                : [
                    _c(
                      "v-col",
                      {
                        staticStyle: { "max-width": "300px" },
                        attrs: { cols: "auto" }
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            label: "Status",
                            items: _vm.promo_statuses,
                            "item-text": "name",
                            "item-value": "id",
                            "menu-props": { offsetY: true },
                            multiple: "",
                            chips: "",
                            clearable: "",
                            disabled: _vm.loading,
                            "background-color": "#fff",
                            "item-color": "action",
                            dense: "",
                            "hide-details": "",
                            outlined: ""
                          },
                          on: {
                            "click:clear": function($event) {
                              _vm.selectedStatuses = []
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "selection",
                              fn: function(ref) {
                                var item = ref.item
                                var index = ref.index
                                return [
                                  index === 0
                                    ? _c("v-chip", { attrs: { small: "" } }, [
                                        _c("span", [_vm._v(_vm._s(item.name))])
                                      ])
                                    : _vm._e(),
                                  index === 1
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "grey--text text-caption"
                                        },
                                        [
                                          _vm._v(
                                            " (+" +
                                              _vm._s(
                                                _vm.selectedStatuses.length - 1
                                              ) +
                                              ") "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.selectedStatuses,
                            callback: function($$v) {
                              _vm.selectedStatuses = $$v
                            },
                            expression: "selectedStatuses"
                          }
                        })
                      ],
                      1
                    )
                  ],
              _c(
                "v-col",
                { staticClass: "ma-0 pa-0" },
                [
                  _c("DateFilters", {
                    attrs: { disabled: _vm.loading },
                    model: {
                      value: _vm.dateParams,
                      callback: function($$v) {
                        _vm.dateParams = $$v
                      },
                      expression: "dateParams"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pl-1 pr-0", attrs: { cols: "4" } },
                [
                  _c("SearchFilters", {
                    attrs: { disabled: _vm.loading },
                    on: { search: _vm.getPromos },
                    model: {
                      value: _vm.searchParams,
                      callback: function($$v) {
                        _vm.searchParams = $$v
                      },
                      expression: "searchParams"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pl-0", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: {
                        "border-top-left-radius": "0px",
                        "border-bottom-left-radius": "0px",
                        border: "1px solid #9e9e9e",
                        "border-left": "0px"
                      },
                      attrs: {
                        loading: _vm.loading,
                        disabled: _vm.loading,
                        height: "40",
                        depressed: "",
                        color: "accent"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.getPromos($event)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-magnify")])],
                    1
                  )
                ],
                1
              ),
              _vm.hasWriteAccess
                ? _c(
                    "v-btn",
                    {
                      staticClass: "pl-3 pr-2 white--text",
                      attrs: {
                        elevation: "0",
                        color: _vm.promoAddColor,
                        height: "40"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.showCategoryModal = true
                        }
                      }
                    },
                    [
                      _vm._v(" Promo "),
                      _c("v-icon", { staticClass: "ml-1" }, [
                        _vm._v("mdi-plus")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tenantUsesContractUpload
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-2 white--text",
                                      attrs: {
                                        color: "accent",
                                        height: "40",
                                        small: "",
                                        fab: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          _vm.uploadModal = true
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-file-upload-outline")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2981904676
                      )
                    },
                    [_vm._v(" Create promotions via contract file ")]
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c("v-divider"),
      _c("v-data-table", {
        staticStyle: { cursor: "pointer" },
        attrs: {
          color: "#fff",
          "loading-text": "Loading... Please wait",
          headers: _vm.headers,
          height: _vm.tableSize,
          "items-per-page": _vm.pageSize,
          items: _vm.promos,
          loading: _vm.loading,
          "hide-default-footer": "",
          "fixed-header": "",
          "sort-by": _vm.tableSort.key,
          "sort-desc": _vm.tableSort.desc,
          "group-by": _vm.showGroupedPromos ? "file_name" : undefined,
          dense: ""
        },
        on: {
          "update:items": function($event) {
            _vm.promos = $event
          },
          "update:sort-by": _vm.setTableSortKey,
          "update:sort-desc": _vm.setTableSortDesc,
          "dblclick:row": _vm.viewPromo
        },
        scopedSlots: _vm._u(
          [
            {
              key: "no-data",
              fn: function() {
                return [
                  !_vm.loading
                    ? _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          !_vm.activeSearch
                            ? _c("v-card-text", [_vm._v(" No promos to show ")])
                            : _c("v-card-text", [_vm._v(" No results found ")])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "group.header",
              fn: function(ref) {
                var group = ref.group
                var items = ref.items
                var headers = ref.headers
                var toggle = ref.toggle
                var isOpen = ref.isOpen
                return [
                  _c(
                    "td",
                    { attrs: { colspan: headers.length } },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" }, on: { click: toggle } },
                        [
                          _c(
                            "v-btn",
                            {
                              ref: group,
                              staticClass: "my-auto ml-3",
                              attrs: {
                                small: "",
                                icon: "",
                                "data-open": isOpen
                              }
                            },
                            [
                              _c("v-icon", [
                                _vm._v(
                                  _vm._s(
                                    isOpen
                                      ? "mdi-chevron-up"
                                      : "mdi-chevron-down"
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                          _c("v-col", [
                            !items[0].has_contract
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "font-italic font-weight-bold"
                                  },
                                  [_vm._v(" No contract file ")]
                                )
                              : _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(group.split("/").pop()) + " "
                                    )
                                  ]
                                )
                          ]),
                          items[0].has_contract
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "ml-auto",
                                  attrs: { cols: "2" }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        " Upload date: " +
                                          _vm._s(items[0].upload_date) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              staticClass: "ml-auto",
                              staticStyle: { "max-width": "8rem" }
                            },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(" Promos: " + _vm._s(items.length) + " ")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "header.expanded",
              fn: function() {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.promos.length === 0,
                        dark: "",
                        small: "",
                        icon: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.anyGroupsExpanded ? _vm.closeAll() : _vm.openAll()
                        }
                      }
                    },
                    [
                      _vm.anyGroupsExpanded
                        ? _c("v-icon", [_vm._v("mdi-chevron-up")])
                        : _c("v-icon", [_vm._v("mdi-chevron-down")])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "header.download",
              fn: function(ref) {
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: { "open-on-hover": "", "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "", dark: "", small: "" }
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", { staticClass: "mx-auto" }, [
                                      _vm._v(" mdi-download ")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "grey--text text--darken-1" },
                                [_vm._v(" Select export type ")]
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.exportPromos("simple")
                                }
                              }
                            },
                            [_c("v-list-item-title", [_vm._v("Simple")])],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.exportPromos("detailed")
                                }
                              }
                            },
                            [_c("v-list-item-title", [_vm._v("Detailed")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.lump_sum_allowance",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.lump_sum_allowance
                    ? _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(item.lump_sum_allowance.toFixed(2)) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.start_dt",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " + _vm._s(_vm.$config.formatDate(item.start_dt)) + " "
                  )
                ]
              }
            },
            {
              key: "item.end_dt",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " + _vm._s(_vm.$config.formatDate(item.end_dt)) + " "
                  )
                ]
              }
            },
            {
              key: "item.ad_date",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " + _vm._s(_vm.$config.formatDate(item.ad_date)) + " "
                  )
                ]
              }
            },
            {
              key: "item.download",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "button",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.download(item)
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mx-auto" }, [
                        _vm._v(" mdi-download ")
                      ])
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.download_contract",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.has_contract
                    ? _c(
                        "button",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.downloadContract(item)
                            }
                          }
                        },
                        [
                          _c("v-icon", { staticClass: "mx-auto" }, [
                            _vm._v(" mdi-file-download ")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.updated_on",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "open-on-hover": "",
                            top: "",
                            "offset-y": "",
                            left: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        _vm._b(
                                          { staticClass: "primary--text" },
                                          "span",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.updatedOn(item)) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "pa-2" },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.updatedBy(item)))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "footer",
              fn: function() {
                return [
                  _c("PagingFooter", {
                    attrs: {
                      pageSizes: _vm.pageSizes.concat(
                        _vm.tenant === "pricechopper" ? [500] : []
                      ),
                      pageSize: _vm.pageSize,
                      page: _vm.page,
                      disablePrevious: _vm.disablePrevious,
                      disableNext: _vm.disableNext,
                      showFirstPage: _vm.showFirstPage
                    },
                    on: {
                      "update:pageSize": function($event) {
                        _vm.pageSize = $event
                      },
                      "update:page-size": function($event) {
                        _vm.pageSize = $event
                      },
                      first: _vm.setFirstPage,
                      previous: _vm.previous,
                      next: _vm.next
                    }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _vm.showCategoryModal
        ? _c("PromoCategoryModal", {
            attrs: { responsiblePartyId: _vm.responsibleParty.id },
            on: {
              cancel: function($event) {
                _vm.showCategoryModal = false
              }
            },
            model: {
              value: _vm.showCategoryModal,
              callback: function($$v) {
                _vm.showCategoryModal = $$v
              },
              expression: "showCategoryModal"
            }
          })
        : _vm._e(),
      _vm.uploadModal
        ? _c("ContractUploadModal", {
            on: { closeUpload: _vm.closeUploadModal },
            model: {
              value: _vm.uploadModal,
              callback: function($$v) {
                _vm.uploadModal = $$v
              },
              expression: "uploadModal"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }