// This mixin is intended to manage attributes of the
// promo view components to reduce code duplication 

// API
import Promos from '@/axios/promotion-endpoint'
import PromoStore from '@/axios/promotion-store-endpoint'
import PromoItem from '@/axios/promotion-item-endpoint'
import Items from '@/axios/items-endpoint'
import PartyRel from '@/axios/party-relationship-endpoint'
import PromoBillingParties from '@/axios/promotion-billing-party-endpoint'
import Search from '@/axios/search-endpoint'
import ContractUpload from '@/axios/contract-upload-endpoint.js'

// mixins
import { partySearch } from '@/mixins/party-search.js'
import { userAccess } from '@/mixins/user-access'
import { displayAlert } from '@/mixins/alert'
import { utils } from '@/mixins/utils'
// Third Party Helpers
import { cloneDeep } from 'lodash'

export const promoProperties = {
  data () {
    return {
      expandedPanel: 0,
      confirmationModal: false,
      showCloneModal: false,
      showShuttleModal: false,
      showStatusModal: false,
      tab: 0,
      itemSourceCache: {},
      partyItemSources: [],
      promoSelectedSubvendors: [],
      promoBillingParties: [],
      billingPartiesBuffer: {},
      promo: {
        name: '',
        start_dt: '',
        end_dt: '',
        promotion_type: {},
        enforce_compliance: false,
        override_store_offset: false,
        override_store_length: false,
        billing_split_type: {},
        item_source: null,
        promo_category: null,
        billback_allowance: null,
        amap_allowance: null,
        early_buy_allowance: null,
        lump_sum_allowance: null,
        promo_number: '',
        subvendor_party_ids: [],
        digital_coupon: false,
        digital_coupon_note: null,
        private_label: false,
        special_ad_length: false
      },
      deleting: false,
      saving: false,
      itemsDisabled: true,
      promoPartyIds: [],
      shuttleEnabled: false,
      promoAllowances: [],
      formatFields: [
        'retail_price', 'promotion_price', 'amount_off',
        'rebate_amount', 'max_rebate_amount', 'billback_allowance',
        'amap_allowance', 'early_buy_allowance', 'lump_sum_allowance'
      ]
    }
  },
  mixins: [partySearch, userAccess, displayAlert, utils],
  beforeCreate () {
    this.$store.dispatch('getPromoKeyValues').then(() => {
      if (!this.$route?.query?.id) {
        this.setDefaultPromoClass()
      }
    })
  },
  async created () {
    this.$store.dispatch('getResponsibleParties')
    if (this.limitAccessByUserParties && this.userRelatedParties.length === 0) {
      await this.$store.dispatch('getUserRelatedParties')
    }
    if (this.$route.query.id) {
      await this.initPromo()
      await this.getPromoStores()
    } else {
      const { promo_category, responsible_party_id } = this.$route.params
      this.promo.responsible_party_id = responsible_party_id
      if (promo_category) {
        this.promo.promo_category = promo_category
        this.promo.promotion_type = promo_category.promo_type
        if (this.billingModeOptions.length === 1) {
          this.promo.billing_mode = this.billingModeOptions[0]
        }
        if (!['awg', 'pricechopper', 'alliance-retail-group', 'jbg'].includes(this.tenant) && promo_category.scan_promotion) {
          this.promo.enforce_compliance = true
        }
        if (this.tenant === 'alliance-retail-group' && ['epay', 'compete'].some(keyword => promo_category.name.toLowerCase().includes(keyword))) {
          this.promo.enforce_compliance = true
          this.promo.variance = 0.05
        }
        if (this.tenant === 'awg' && ['edlc scan', 'edlc billback', 'required retail'].some(keyword => promo_category.name.toLowerCase().includes(keyword))) {
          this.promo.enforce_compliance = true
          this.promo.variance = 0.05
        }
      }
      this.getPromoNumber()
    }
  },
  watch: {
    validSources: {
      handler () {
        if (this.promoPartyIds.length > 0 && this.tenantHasSourceDistinction) {
          this.setPartyItemSources(this.promoPartyIds)
        }
      }
    },
    '$route.query.id': {
      handler (newValue, oldValue) {
        if (newValue && oldValue) {
          this.initPromo()
        }
      }
    },
    'promo.digital_coupon': {
      handler(newValue) {
        if (newValue === false) {
          this.promo.digital_coupon_note = null
        }
      }
    },
    userRelatedPartyIds: {
      handler () {
        if (this.limitCategoriesByUserParties) {
          this.$store.dispatch('getUserCategories')
        }
      },
      deep: true
    }
  },
  computed: {
    billing_modes() {
      return this.$store.getters.billing_modes.filter((mode) => {
        if (mode.constant === 'AMAP') {
          return this.promo.promo_category?.name?.toLowerCase()?.includes('amap')
        }
        return true
      })
    },
    validBillingModes() {
      const validIds = this.promo.promo_category?.valid_billing_modes || []
      if (validIds.length > 0) {
        return this.billing_modes.filter((mode) => validIds.includes(mode?.id))
      }
      return this.billing_modes
    },
    billingModeOptions() {
      let options = [...this.validBillingModes]
      if (this.promo.billing_mode?.id && options.length > 0) {
        const validIds = this.validBillingModes.map((m) => m.id)
        // if an invalid billing mode was selected prior to validation rules being implemented,
        // we still want to show that to the user.
        // in all other cases, invalid options won't be available.
        if (!validIds.includes(this.promo.billing_mode.id)) {
          options.push(this.promo.billing_mode)
        }
      }
      return options
    },
    allowMultipleSubvendors () {
      return !['pricechopper', 'alliance-retail-group'].includes(this.tenant)
    },
    renderPromoSubvendorSelect () {
      return ['alliance-retail-group', 'pricechopper'].includes(this.tenant)
    },
    restrictToRelatedSubvendors () {
      return ['alliance-retail-group', 'pricechopper'].includes(this.tenant)
    },
    responsible_parties () {
      return this.$store.getters.responsible_parties
    },
    validSources () {
      if (this.responsible_parties.length > 0) {
        const party = this.responsible_parties.find(party => party?.tenant === this.tenant)
        return party?.sources || []
      }
      return []
    },
    promoItemSources () {
      if (this.partyItemSources.length > 0) {
        const sources = this.primarySources
        // get unique source_ids
        return Array.from(new Set(sources)).sort()
      }
      if (this.validSources.length > 0 && !this.tenantHasSourceDistinction) {
        return this.validSources.map(s => s.source_id).sort()
      }
      return []
    },
    upstreamPartyIds () {
      if (this.partyItemSources.length > 0) {
        const ids = this.partyItemSources.flatMap(i => i.upstreamIds)
        return Array.from(new Set(ids))
      }
      return []
    },
    upstreamPartyRels () {
      if (this.partyItemSources.length > 0) {
        return this.partyItemSources.flatMap(i => i.upstreamRels || [])
      }
      return []
    },
    itemSourceRels () {
      if (this.partyItemSources.length > 0) {
        return this.partyItemSources.flatMap(i => i.sourceRels || [])
      }
      return []
    },
    primarySources () {
      if (this.upstreamPartyRels.length > 0) {
        const validFromRels = ['AD_GROUP_HAS_PARENT', 'SUPPLIED_BY']
        const partyIds = this.upstreamPartyRels.flatMap(rel => {
          return validFromRels.includes(rel.from_rel_type)
            ? rel.to_party_id
            : []
        })
        const sources = partyIds.flatMap(id => {
          const sourceRel = this.itemSourceRels.find(rel => rel.party_id === id)
          return sourceRel?.source_id || []
        })
        return [...new Set(sources)]
      }
      return []
    },
    vmcSources () {
      if (this.upstreamPartyRels.length > 0) {
        const partyIds = this.upstreamPartyRels.flatMap(rel => {
          return (rel.from_rel_type === 'ALT_AD_GROUP_HAS_PARENT')
            ? rel.to_party_id
            : []
        })
        const vmcSources = partyIds.flatMap(id => {
          const sourceRel = this.itemSourceRels.find(rel => rel?.party_id === id)
          return sourceRel?.source_id || []
        })
        return [...new Set(vmcSources)]
      }
      return []
    },
    isUserRelatedPromo () {
      return this.promoPartyIds.every(id => this.userRelatedPartyIds.includes(id))
    },
    isPromoStatusAdmin () {
      if (this.hasStatusAdminAccess) {
        return !this.limitAccessByRelatedStores || this.isUserRelatedPromo
      }
      return false
    },
    isApprovedPromo () {
      return this.promo.status?.status?.constant === 'APPROVED'
    },
    readOnlyPromo () {
      if (!this.$route.query.id) return false
      if (this.promo.id && this.hasWriteAccess && !this.isApprovedPromo) {
        return (this.readOnlyCategory && !this.isPromoStatusAdmin)
      }
      return true
    },
    readOnlyCategory () {
      const catName = this.promo?.promo_category?.name
      if (catName) {
        return this.readOnlyCategories.includes(catName)
      }
      return true
    },
    readOnlyCategories () {
      return this.$store.state.readOnlyCategories
    },
    show () {
      return !(this.expandedPanel === 0)
    },
    end_dt () {
      return (this.promo.end_dt !== '') ? this.$config.formatDate(this.promo.end_dt) : ''
    },
    existingPromo () {
      return (this.$route.query.id) ? true : false
    },
    id () {
      return (this.$route.query.id) ?? this.promo.id
    },
    item_source () {
      return this.promo.item_source
    },
    promo_type_constant () {
      if (this.promo.promo_category) {
        return this.promo.promo_category.promo_type.constant
      } else {
        return this.$route.params?.promo_category?.promo_type?.constant || ''
      }
    },
    userPromoCategoryIds () {
      return this.$store.getters.userPromoCategoryIds
    },
    promoTypeDisplayName () {
      return this.promo?.promo_category?.name || ''
    },
    promo_classes () {
      return this.$store.getters.promo_classes
    },
    start_dt () {
      return (this.promo.start_dt !== '') ? this.$config.formatDate(this.promo.start_dt) : ''
    },
    isAMAPorEBA() {
      return ['AMAP', 'Early Buy Allowance'].includes(this.promo?.promo_category?.name)
    },
    vendorIds () {
      if (this.$route.query?.id && this.tenantRequiresVendor) {
        return this.promoBillingParties.flatMap(party => {
          return party?.vendor_id || []
        })
      }
      return []
    },
    lumpSumTotal () {
      if (this.promoAllowances.length > 0) {
        const allowances = this.promoAllowances.map(a => this.getNumericPrice(a.allowance))
        const allowanceTotal = allowances.reduce((total, value) => {
          return total + value
        }, 0)
        return Number.parseFloat(allowanceTotal.toFixed(2))
      }
      return 0
    },
    validLumpSums () {
      const promoLumpSum = this.getNumericPrice(this.promo.lump_sum_allowance)
      if (promoLumpSum > 0) {
        return (this.lumpSumTotal === promoLumpSum)
      }
      return true
    },
    validSubvendorSelected () {
      if (['alliance-retail-group','pricechopper'].includes(this.tenant)) {
        return (this.promoSelectedSubvendors.length > 0)
      } 
      return true
    },
    validCategoryManagerSelected () {
      return ['pricechopper', 'alliance-retail-group'].includes(this.tenant) && Boolean(this.promo.category_manager_id)
    },
    showContractDownloadButton () {
      return ((this.tenantUsesContractUpload) && !['AMAP', 'Early Buy Allowance'].includes(this.promo?.promo_category?.name) && this.promo?.file_name)
    },
    showShuttleButton () {
      return (this.promo.id && this.promo?.classification?.constant === 'TEMPLATE' && this.shuttleEnabled)
    },
    showLumpSum () {
      return ['pricechopper', 'alliance-retail-group'].includes(this.tenant)
        ? (this.promo.billing_mode?.constant === 'AD')
        : true
    },
    showEnforce () {
      return this.promo.promo_category && (this.promo.promo_category.scan_promotion || (this.promo.promo_category.name == 'EDLC Billback' && this.tenant == 'awg'))
    },
    autoEnforce () {
      if (this.tenant == 'awg') {
        if (['EDLC Scan', 'EDLC Billback'].includes(this.promo.promo_category.name) || this.promo.promo_category.name.includes('Required Retail')) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    updateBillingPartyBuffer (billingPartiesBuffer) {
      this.billingPartiesBuffer = billingPartiesBuffer
    },
    statusSaved () {
      this.showStatusModal = false
      this.initPromo()
    },
    initClonedPromo (promoId, promoName) {
      this.showCloneModal = false
      return this.$router.replace({
        name: 'Promo',
        query: {
          id: promoId,
        }
      }).then(() => {
        this.emitAlert(true, 'success', `${promoName} has been successfully created.`, [], true)
      })
    },
    setPromoPartyIds (ids) {
      this.promoPartyIds = ids
      if (this.tenantHasSourceDistinction) this.setPartyItemSources(ids)
    },
    async setPartyItemSources (partyIds) {
      if (this.validSources.length > 1) {
        try {
          let mapped = []
          const res = await this.getUpstreamPartyRels(partyIds)
          if (res?.data?.length > 0) {
            const upstreamIds = [...new Set(res.data.map(result => result.to_party_id))]
            const results = await this.getUpstreamSourceRels(upstreamIds)
            if (results.length > 0) {
              mapped = partyIds.map(party_id => {
                const upstreamRels = res.data.filter(r => r.from_party_id === party_id)
                const upstreamIds = upstreamRels.map(r => r.to_party_id)
                const sourceRels = results.filter(result => upstreamIds.includes(result.party_id))
                const sources = sourceRels.map(s => s.source_id)
                return { party_id, sources, upstreamIds, upstreamRels, sourceRels }
              })
            }
          }
          this.partyItemSources = mapped
        } catch (err) {
          this.handleError(err)
        }
      }
    },
    async getUpstreamPartyRels (partyIds) {
      if (partyIds.length === 0) return []
      const payload = {
        from_party_ids: partyIds,
        excluded_upstream_types: ['WHOLESALER', 'REGION_GROUP', 'STORE_GROUP', 'AD_GROUP', 'OWNER']
      }
      return PartyRel.getUpstreamRelationships(payload)
    },
    async getUpstreamSourceRels(upstreamIds) {
      const promises = upstreamIds.map(id => {
        const sourceRels = this.itemSourceCache[id]
        return sourceRels || Items.getSourceRelsByPartyId(id).then(res => {
          this.itemSourceCache[id] = res.data
          return res.data
        })
      })
      return this.getAllSettled(promises)
    },
    async updatePartyItemSources (partyId) {
      if (this.partyItemSources.length > 0) {
        const partyObj = this.partyItemSources.find(i => i.party_id === partyId)
        this.partyItemSources = this.partyItemSources.filter(obj => obj.party_id !== partyId)
        const itemRemovalSources = partyObj.sources.filter(sourceId => {
          return !this.promoItemSources.includes(sourceId)
        })
        if (itemRemovalSources.length > 0) {
          const callback = (item) => {
            if (itemRemovalSources.includes(item.item.source)) {
              return PromoItem.delete(item.id)
            }
          }
          await this.removePromoItems(callback)
        }
        await this.updatePromoHistory()
      }
    },
    async removePromoItems (callback) {
      const id = this.$route.query.id
      try {
        const res = await PromoItem.getItemsByPromo({
          promo_id: id, 
          offset: 0,
          limit: 200, 
          paging: true,
          callback
        })
        const rejected = res.data.filter(r => r.status === 'rejected')
        if (rejected.length > 0) throw rejected
        return res.data
      } catch (err) {
        this.handleError(err)
      }
    },
    setDefaultPromoClass () {
      const normalClass = this.promo_classes.find(c => c.constant === 'NORMAL')
      this.promo.classification = normalClass
    },
    async deletePromo () {
      try {
        this.deleting = true
        await Promos.delete(this.id)
        this.emitAlert(true, 'success', this.promo.name + ' has been successfully deleted.', [], true)
        this.deleting = false
        return this.$router.push({ name: 'Dashboard'})
      } catch (err) {
        this.deleting = false
        this.confirmationModal = false
        this.handleError(err)
      }
    },
    async initPromo () {
      this.initBillingParties()
      const id = this.$route.query.id
      try {
        const res = await Promos.get(id)
        if (res?.data) {
          this.promo = this.formatPromo(res.data)
          if (res.data.subvendor_party_ids && res.data.subvendor_party_ids.length > 0) {
            const uuids = res.data.subvendor_party_ids
            const subvendorParties = await this.initSubvendorParties(uuids)
            this.promoSelectedSubvendors = subvendorParties.sort((a, b) => a.name.localeCompare(b.name))
          } else {
            this.promoSelectedSubvendors = []
          }
        }
      } catch (err) {
        this.handleError(err)
      }
    },
    formatPromo (promo) {
      for (const field of this.formatFields) {
        promo[field] = this.$config.formatCurrency(promo[field])
      }
      return promo
    },
    async initSubvendorParties(uuids) {
      const term = uuids.join(' ')
      const operator = 'or'
      try {
        const subvendorPartiesResponse = await Search.customerSearch(term, operator)
        if (Array.isArray(subvendorPartiesResponse.data)) {
          const selectedSubvendors = subvendorPartiesResponse.data
            .filter(party => party.party_type === 'SUBVENDOR' && uuids.includes(party.party_id))
            .map(party => {
              party.name = party.party_name
              party.display_name = `${party.name}`
              return party
            })
          return selectedSubvendors
        }
      } catch (err) {
        this.handleError(err)
      }
    },
    async initBillingParties () {
      const promoId = this.$route.query.id
      // get billing parties on promo and create list
      if (promoId) {
        let billingParties = []
        try {
          const billingPartiesResponse = await PromoBillingParties.getList(promoId, 0, 25, true)
          if (billingPartiesResponse?.data?.length > 0) {
            billingParties = await this.getBillingPartyDetails(billingPartiesResponse.data)
          }
          this.promoBillingParties = billingParties
        } catch (err) {
          this.handleError(err)
        }
      }
    },
    async savePromo () {
      this.promo = this.$config.nullifyEmptyStrings(this.promo)
      this.saving = true
      const valid = await this.validatePromo()
      if (!valid) {
        return this.saving = false
      }
      const promoSaveObj = cloneDeep(this.promo)
      this.$config.objectStringsToFloat(promoSaveObj)
      if (promoSaveObj.responsible_party_id?.id) {
        promoSaveObj.responsible_party_id = promoSaveObj.responsible_party_id.id
      }
      try {
        if (this.existingPromo) {
          await this.updatePromo(this.id, promoSaveObj)
        } else {
          await this.createPromo(promoSaveObj)
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.saving = false
      }
    },
    async createPromo(promo) {
      promo.billing_split_type = {
        id: this.$store.getters.billing_split[0].id
      }
      if (this.validSources?.length === 1) {
        promo.item_source = this.validSources[0].source_id
      }
      
      return Promos.post(promo)
        .then(async response => {
          const { included } = this.billingPartiesBuffer
          if (included?.length > 0) {
            this.billingPartiesBuffer = {
              added: included,
              removed: []
            }
            await this.commitBillingParties(response.data.id)
          }

          this.$router.replace({
            name: 'Promo',
            query: { id: response.data.id },
            params: { promo_type: promo.promotion_type }
          })

          await this.initPromo()

          return this.emitAlert(true, 'success', promo.name + ' has been successfully created. Please add parties and items to the promo', [], true)
        })
        .catch(err => {
          throw err
        })
    },
    async download () {
      try {
        const res = await Promos.download_csv(this.id)
        if (res?.data) {
          location.href = res.data
        }
      } catch (err) {
        this.handleError(err)
      }
    },
    async downloadContract () {
      try {
        const res = await ContractUpload.downloadContract({ promo_id: this.id })
        if (res?.data?.url) {
          location.href = res.data.url
        }
      } catch (err) {
        this.handleError(err)
      }
    },
    handleSubvendorSelected(subvendors) {
      this.promo.subvendor_party_ids = subvendors.flatMap(subvendor => subvendor?.party_id || [])
    },
    async updatePromo(id, promo) {
      try {
        await Promos.put(id, promo)
        await this.initPromo()
        return this.emitAlert(true, 'success', promo.name + ' has been successfully updated.', [], true)
      } catch (err) {
        this.handleError(err)
      }
    },
    async commitBillingParties(promoID) {
      const { added, removed } = this.billingPartiesBuffer
      let removedVendorIds = []
      if (this.tenantRequiresVendor && removed.length > 0) {
        removedVendorIds = removed.map(billingParty => billingParty.vendor_id)
      }
      const addPromises = added.flatMap(billingParty => {
        if (billingParty) {
          const payload = {
            party_id: billingParty.party_id,
            billing_override: false,
            promo_id: promoID
          }
          return PromoBillingParties.post(payload)
        }
        return []
      })
      const removePromises = removed.flatMap(billingParty => {
        return billingParty ? PromoBillingParties.delete(promoID, billingParty.party_id) : []
      })
      try {
        const allPromises = [...addPromises, ...removePromises]
        const { rejected } = await this.getAllSettled(allPromises, true)
        if (rejected.length > 0) throw rejected
        if (removedVendorIds.length > 0) {
          await this.removeBillingPartyItems(removedVendorIds)
        }
        this.billingPartiesBuffer = {}
        await this.updatePromoHistory(promoID)
      } catch (err) {
        this.handleError(err)
      } finally {
        await this.initBillingParties()
      }
    },
    async removeBillingPartyItems (vendorIds) {
      const callback = (item) => {
        if (vendorIds.includes(item.item.vendor_id)) {
          return PromoItem.delete(item.id)
        }
      }
      // this returns a promise, which we'll unwrap in the method above
      return this.removePromoItems(callback)
    },
    async getPromoNumber() {
      try {
        const res = await Promos.suggestedPromoNumber()
        this.promo.promo_number = res.data.promo_number
      } catch (err) {
        this.handleError(err)
      }
    },
    async updatePromoHistory (promoId = null) {
      const updateHistory = Boolean(this.$route.query.id)
      if (!updateHistory) return
      try {
        const res = await Promos.updateHistory(promoId || this.$route.query.id)
        if (res?.data) {
          this.checkAuditDiff(res.data)
        }
      } catch (err) {
        this.handleError(err)
      }
    },
    checkAuditDiff (newAudit) {
      const oldDisplayDate = this.$config.formatDate(this.promo.audit.updated_on)
      const newDisplayDate = this.$config.formatDate(newAudit.updated_on)

      if (this.promo.audit.updated_by !== newAudit.updated_by) {
        this.promo.audit.updated_by = newAudit.updated_by
      }
      if (oldDisplayDate !== newDisplayDate) {
        this.promo.audit.updated_on = newAudit.updated_on
      }
    },
    enforceComplianceChanged (newValue) {
      if (this.tenantDefaultVariance[this.tenant] != null) {
        this.promo.variance = (newValue === false) 
          ? null 
          : this.tenantDefaultVariance[this.tenant]
      }
    },
    isValidBillingMode (value) {
      if (this.billingModeOptions.length === 0) return true
      if (value) {
        const validIds = this.validBillingModes.flatMap(m => m?.id || [])
        return (validIds.includes(value.id)) || 'Invalid promotion type'
      }
      return 'Promotion Type is required'
    },
    async queueBillingPartyChanges(changedBillingParties) {
      const promoID = this.$route.query.id
      this.billingPartiesBuffer = changedBillingParties
      this.updateBillingPartyBuffer(changedBillingParties)
      if (promoID) {
        await this.commitBillingParties(promoID)
      }
    },
    async validatePromo () {
      const validBillingParties = this.$refs.vendorSelect.validate()
      const validBaseFields = this.$refs.form.validate()
      const validDateFields = this.$refs.promoDates.validate()
      const validTypeFields = await this.$refs.promoTypeFields.validate()
      if (!validTypeFields || !validDateFields) {
        return false
      }
      if (!(validBaseFields && validBillingParties)) {
        this.emitAlert(true, 'warning', 'Please check your form for errors')
        return false
      }
      return true
    },
    async getPromoStores () {
      try {
        const { data } = await PromoStore.getStores(this.id, 250, 0)
        if (data?.length > 0) {
          const partyIds = data.map(s => s.store_id)
          this.setPromoPartyIds(partyIds)
          this.itemsDisabled = false
        }
      } catch (err) {
        this.handleError(err)
      } 
    }
  }
}
