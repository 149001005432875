<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-expansion-panels flat v-model="expandedPanel">
      <v-expansion-panel>
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on }">
            <v-expansion-panel-header v-on="on" color="grey lighten-3">
              <template v-slot:default>
                <v-row align="center">
                  <router-link :to="{ path: '/promos'}" class="returnLink">
                    <v-icon color="primary">mdi-chevron-left</v-icon>
                    Return to Promotions
                  </router-link>
                  <v-spacer />
                  <small v-if="show">
                    <strong>Promotion Dates:</strong> ( {{$config.formatDate(promo.start_dt)}} - {{$config.formatDate(promo.end_dt)}} )
                    <v-icon color="#000">mdi-circle-small</v-icon>
                    <strong>Promotion Name:</strong> ( {{promo.name}} )
                    <v-icon color="#000">mdi-circle-small</v-icon>
                    <strong>Promotion Category:</strong> ( {{promoTypeDisplayName}} )
                    <v-icon color="#000">mdi-circle-small</v-icon>
                    <strong>Promotion Number:</strong> ( {{promo.promo_number}} )
                    <span v-if="promo.status">
                      <v-icon color="#000">mdi-circle-small</v-icon>
                      <strong>Status:</strong> ( {{promo.status.status.name}} )
                    </span>
                    <v-icon color="#000">mdi-circle-small</v-icon>
                    <strong>Last updated by:</strong> ( {{promo.updated_by}} )
                  </small>
                  <v-spacer />
                </v-row>
              </template>
            </v-expansion-panel-header>
          </template>
          <span>
            <v-icon small color="#fff">
              {{ (show) ? 'mdi-arrow-expand-vertical' : 'mdi-arrow-collapse-vertical' }}
            </v-icon>
            {{ (show) ? 'Expand Promotion Details' : 'Collapse Promotion Details' }}
          </span>
        </v-tooltip>
        <v-expansion-panel-content color="grey lighten-3">
          <span class="mr-2">
            Promotion Details 
          </span>
          <span class="ml-5 text-body-2">
            <b>Promotion Category:</b> {{ promoTypeDisplayName }}
          </span>
          <span
            v-if="promo.status"
            class="text-body-2 ml-4 mr-6"
            style="position: relative">
              <b>Status:</b> {{ promo.status.status.name }}
              <v-btn
                absolute
                class="mr-2"
                style="top: -10px"
                fab
                x-small
                elevation="2"
                @click="showStatusModal = true">
                <v-icon small>
                  {{ (readOnlyPromo && !isPromoStatusAdmin) ? 'mdi-view-headline' : 'mdi-pencil' }}
                </v-icon>
              </v-btn>
          </span>
          <template v-if="promo.audit">
            <span class="ml-6 text-body-2">
              <b>Last updated by:</b> {{ promo.audit.updated_by }}
            </span>
            <span class="ml-4 text-body-2">
              <b>Created On:</b> {{ $config.formatDate(promo.audit.created_on) }}
            </span>
            <span class="ml-4 text-body-2">
              <b>Updated On:</b> {{ $config.formatDate(promo.audit.updated_on) }}
            </span>
          </template>
          <span v-if="promo.cloned_from_promo_id"
            class="ml-4 text-body-2">
            <router-link
              :to="{ name: 'Promo', query: { id: promo.cloned_from_promo_id }}"
              style="text-decoration: none"
              class="primary--text">
              Parent <v-icon small color="primary">mdi-launch</v-icon>
            </router-link>
          </span>
          <PromoHeader
            ref="promoTypeFields"
            :promo_type_constant="promo_type_constant"
            :promo="promo"
            :readOnlyPromo="readOnlyPromo"
            :showLumpSum="showLumpSum">
          </PromoHeader>
          <v-form ref="form">
          <v-row class="promoRow">
            <v-col cols="4" class="px-0 py-0">
              <PromoDates
                ref="promoDates"
                :promo="promo"
                :readOnlyPromo="readOnlyPromo">
              </PromoDates>
            </v-col>
            <v-col cols="2" class="px-1">
              <v-select
                label="Promotion Type"
                item-value="id"
                item-text="name"
                item-color="action"
                :items="billingModeOptions"
                v-model="promo.billing_mode"
                background-color="#fff"
                :rules="[isValidBillingMode]"
                :readonly="readOnlyPromo"
                dense
                hide-details="auto"
                outlined
                return-object>
              </v-select>
            </v-col>
            <v-col cols="2" class="px-1">
              <PromoVendorSelect
                ref="vendorSelect"
                :promoBillingParties="promoBillingParties"
                :readOnlyPromo="readOnlyPromo"
                @queueChanges="queueBillingPartyChanges">
              </PromoVendorSelect>
            </v-col>
            <template v-if="renderPromoSubvendorSelect && !restrictToRelatedSubvendors">
              <v-col cols="2" class="px-1">
                <PromoSubvendorSelect
                  ref="subvendorSelect"
                  :promoBillingParties="promoBillingParties"
                  :promoSelectedSubvendors="promoSelectedSubvendors"
                  :readOnlyPromo="readOnlyPromo"
                  @subvendorSelected="handleSubvendorSelected">
                </PromoSubvendorSelect>
              </v-col>
            </template>
            <template v-else-if="renderPromoSubvendorSelect && restrictToRelatedSubvendors">
              <v-col cols="2" class="px-1">
                <PromoRelatedSubvendorSelect
                  ref="subvendorSelect"
                  :promoBillingParties="promoBillingParties"
                  :promoSelectedSubvendors="promoSelectedSubvendors"
                  :readOnlyPromo="readOnlyPromo"
                  :billingPartiesBuffer="billingPartiesBuffer"
                  :allowMultipleSubvendors="allowMultipleSubvendors"
                  @subvendorSelected="handleSubvendorSelected">
                </PromoRelatedSubvendorSelect>
              </v-col>
            </template>
            <v-col cols="2" v-if="!limitAccessByRelatedVendors" class="px-1">
              <v-select
                label="Classification"
                item-value="id"
                item-text="name"
                item-color="action"
                :items="promo_classes"
                v-model="promo.classification"
                :disabled="readOnlyPromo"
                background-color="#fff"
                dense
                hide-details
                outlined
                return-object>
              </v-select>
            </v-col>
          </v-row>
          <v-row class="promoRow" align="center">
            <v-col :cols="limitAccessByRelatedVendors ? 4 : 3">
              <v-text-field 
                v-if="!id"
                dense
                outlined
                hide-details
                background-color="#fff"
                label="Promotion Number"
                v-model="promo.promo_number">
              </v-text-field>
              <span v-else class="text-body-2">
                <b>Promotion Number:</b> {{ promo.promo_number }}
              </span>
            </v-col>
            <!-- <v-spacer /> -->
            <v-col 
              v-if="!(tenant === 'pricechopper')"
              v-show="!limitAccessByRelatedVendors"
              class="py-0 mt-4">
              <v-layout>
                <v-checkbox
                  color="checkbox"
                  class="mt-0"
                  height="30px"
                  label="Override Store Offset"
                  v-model="promo.override_store_offset"
                  :disabled="readOnlyPromo" />
              </v-layout>
            </v-col>
            <v-col 
              v-if="!(tenant === 'pricechopper')"
              v-show="!limitAccessByRelatedVendors"
              class="py-0 mt-4">
              <v-layout>
                <v-checkbox
                  color="checkbox"
                  class="mt-0"
                  height="30px"
                  label="Override Store Ad Length"
                  v-model="promo.override_store_length"
                  :disabled="readOnlyPromo" />
              </v-layout>
            </v-col>
            <v-col
              v-if="!['pricechopper'].includes(tenant)"
              v-show="showEnforce"
              class="py-0 mt-4">
              <v-layout>
                <v-checkbox
                  color="checkbox"
                  class="mt-0"
                  height="30px"
                  label="Enforce Compliance"
                  v-model="promo.enforce_compliance"
                  @change="enforceComplianceChanged"
                  :disabled="tenant === 'alliance-retail-group' || readOnlyPromo || autoEnforce" />
              </v-layout>
            </v-col>
            <v-col 
              v-if="!['pricechopper'].includes(tenant)"
              class="py-0 mt-4">
              <v-layout>
                <v-checkbox
                  color="checkbox"
                  class="mt-0"
                  height="30px"
                  label="Perishable"
                  v-model="promo.perishable"
                  :disabled="readOnlyPromo" />
              </v-layout>
            </v-col>
            <v-spacer />
            <v-tooltip top v-if="showContractDownloadButton">
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  small
                  v-on="on"
                  elevation="2"
                  class="mr-4"
                  @click="downloadContract">
                  <v-icon class="mx-auto">
                    mdi-file-download
                  </v-icon>
                </v-btn>
              </template>
              <span>Download Contract</span>
            </v-tooltip>
      
            <v-tooltip top v-if="showShuttleButton && !readOnlyPromo">
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  small
                  v-on="on"
                  color="#222"
                  elevation="2"
                  class="mr-4 white--text"
                  @click="showShuttleModal = true">
                  <v-icon>mdi-bus-multiple</v-icon>
                </v-btn>
              </template>
              <span>Shuttle Promo</span>
            </v-tooltip>
      
          <v-tooltip top v-if="promo.id">
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  small
                  v-on="on"
                  elevation="2"
                  class="mr-4"
                  @click="download">
                  <v-icon class="mx-auto">
                    mdi-download
                  </v-icon>
                </v-btn>
              </template>
              <span>Download Promo</span>
            </v-tooltip>
      
            <v-tooltip top v-if="promo.id">
              <template v-slot:activator="{ on }">
                <v-btn
                  :disabled="itemsDisabled"
                  fab
                  small
                  v-on="on"
                  elevation="2"
                  class="mr-4"
                  @click="showCloneModal = true">
                  <v-icon>mdi-plus-box-multiple-outline</v-icon>
                </v-btn>
              </template>
              <span>Copy Promo</span>
            </v-tooltip>
            <v-tooltip top v-if="!readOnlyPromo">
              <template v-slot:activator="{ on }">
                <v-btn
                  :loading="saving"
                  :disabled="saving"
                  fab
                  small
                  v-on="on"
                  elevation="2"
                  color="green"
                  class="mr-2 white--text"
                  @click="savePromo">
                  <v-icon dense>
                    {{ (id) ? 'mdi-update' : ' mdi-content-save-outline' }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ (id) ? 'Update' : 'Create'}} Promo</span>
            </v-tooltip>
            <v-tooltip top v-if="id && hasScope('promo:delete') && !readOnlyPromo">
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  small
                  elevation="2"
                  v-on="on"
                  color="red"
                  class="white--text mx-1"
                  @click="confirmationModal = true">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <span>Delete Promo</span>
            </v-tooltip>
          </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <template>
      <v-divider />
      <v-tabs
        grow 
        dark
        background-color="tab"
        height="40"
        hide-slider
        :active-class="'active'"
        v-model="tab">
        <v-tab :disabled="!existingPromo">Group / Store</v-tab>
        <v-tab :disabled="!existingPromo || itemsDisabled">Items</v-tab>
      </v-tabs>
      <keep-alive>
        <storeTable
          v-if="tab === 0"
          :panel_height="panelHeight - 36.5"
          :promo="promo"
          :readOnlyPromo="readOnlyPromo"
          :partyItemSources="partyItemSources"
          :upstreamPartyIds="upstreamPartyIds"
          :showLumpSum="showLumpSum"
          @enableItemsTab="itemsDisabled = false"
          @setPromoPartyIds="setPromoPartyIds"
          @updateItemSources="updatePartyItemSources"
          @updateShuttleEnabled="(val) => shuttleEnabled = val"
          @setPromoAllowances="(val) => promoAllowances = val"
          @updateHistory="updatePromoHistory">
        </storeTable>
        <itemTable
          v-else
          :panel_height="panelHeight"
          :promo="promo"
          :readOnlyPromo="readOnlyPromo"
          :promo_type_constant="promo_type_constant"
          :item_sources="promoItemSources"
          :promoPartyIds="promoPartyIds"
          :vmc_sources="vmcSources"
          :vendorIds="vendorIds"
          @updateHistory="updatePromoHistory">
        </itemTable>
      </keep-alive>
      <!-- 
        Are You Sure? Modal
      -->
      <v-dialog v-model="confirmationModal" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Are You Sure?
          </v-card-title>
          <v-card-text>
            Deleting a promo is a permanent action than cannot be undone. Are you sure you want to delete this promo?
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1 white--text" @click="confirmationModal = false">
              No, don't delete!
            </v-btn>
            <v-btn :disabled="deleting" :loading="deleting" color="green darken-1 white--text" @click="deletePromo()">
              Yes, I am sure!
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <CloneModal
        v-model="showCloneModal"
        v-if="showCloneModal"
        :promo="promo"
        @success="initClonedPromo"
        @cancel="showCloneModal = false">
      </CloneModal>
      <ShuttleModal
        v-if="showShuttleModal"
        v-model="showShuttleModal"
        :promoPartyIds="promoPartyIds"
        :partyItemSources="partyItemSources"
        @close="showShuttleModal = false">
      </ShuttleModal>
      <StatusModal
        v-if="showStatusModal"
        v-model="showStatusModal"
        :promo="promo"
        :vendorIds="vendorIds"
        :validLumpSums="validLumpSums"
        :lumpSumTotal="lumpSumTotal"
        :validSubvendorSelected="validSubvendorSelected"
        :validCategoryManagerSelected="validCategoryManagerSelected"
        :isPromoStatusAdmin="isPromoStatusAdmin"
        :readOnlyPromo="readOnlyPromo"
        @close="showStatusModal = false"
        @saved="statusSaved">
      </StatusModal>
    </template>
  </v-container>
</template>
<script>
// Components
import itemTable from '@/components/items/itemsOnPromoTable.vue'
import storeTable from '@/components/stores/storesOnPromoTable.vue'
import PromoHeader from '@/components/PromoHeader.vue'
import PromoVendorSelect from '@/components/PromoVendorSelect.vue'
import PromoSubvendorSelect from '@/components/PromoSubvendorSelect.vue'
import PromoRelatedSubvendorSelect from '@/components/PromoRelatedSubvendorSelect.vue'
import PromoDates from '@/components/PromoDates.vue'

const CloneModal = () => import('@/components/CloneModal.vue')
const ShuttleModal = () => import('@/components/ShuttleModal.vue')
const StatusModal = () => import('@/components/StatusModal.vue')

// Mixins
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
import { partySearch } from '@/mixins/party-search'
// promo view component properties have been moved to this mixin so that it may be reused with ARG promo view
import { promoProperties } from '@/mixins/promo'

export default {
  name: 'promo-view',
  components: {
    itemTable,
    storeTable,
    CloneModal,
    ShuttleModal,
    StatusModal,
    PromoHeader,
    PromoVendorSelect,
    PromoSubvendorSelect,
    PromoRelatedSubvendorSelect,
    PromoDates,
  },
  mixins: [displayAlert, utils, userAccess, partySearch, promoProperties],
  computed: {
    panelHeight () {
      let panelHeight = 523.5
      if (this.expandedPanel !== 0) {
        panelHeight = 320
      }
      return panelHeight
    }
  }
}
</script>
<style scoped>
.promoRow {
  height: 60px;
}

.active {
  background: #fff;
  color: #37474F !important;
}

.returnLink {
  text-decoration:none; 
  color: var(--v-primary-base);
}
</style>
